import React, { useEffect, useState } from 'react';
import HomeIcon from '../icons/homeIcon';
import ChargersIcon from '../icons/chargersIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import SettingsIcon from '../icons/settingsIcon';
import IntegrationInstructionsIcon from '../icons/integrationIcon';
import FinanceIcon from '../icons/Finance';
import { useSelector } from 'react-redux';
import UserMgmt from '../icons/userMgmt';
import HelpSupportIcon from '../icons/helpSupportIcon';

const SiteHostAdminSidebarItems = () => {
  // State for the cpo endpoint
  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";


  // Sidebar items
  const sidebarItems = [
    {
      name: "Home",
      icon: HomeIcon ,
      route: `/csms/${cpoEndpoint}/home`,
    },
    {
      name: "User Management",
      icon: UserMgmt ,
      route: `/user-management`,
      children: [
        {
          name: "My Users",
          route: `/csms/${cpoEndpoint}/user-management/my-users`,
        },
        {
          name: "My Customers",
          route: `/csms/${cpoEndpoint}/user-management/my-customers`,
        }
      ],
    },
    {
      name: "Charger Management",
      icon: ChargersIcon ,
      route: `/charger-management`,
      children: [
        {
          name: "EVSE Stations",
          route: `/csms/${cpoEndpoint}/charger-management/evse-stations`,
        },
        {
          name: "Chargers",
          route: `/csms/${cpoEndpoint}/charger-management/chargers`,
        },
        {
          name: "Base Rate Management",
          route: `/csms/${cpoEndpoint}/charger-management/base-rate-management`,
        },
        {
          name: "Charging Sessions",
          route: `/csms/${cpoEndpoint}/charger-management/sessions`
        }
      ],
    },
    {
      name: "Financial Management",
      icon: FinanceIcon ,
      route: `/financial-management`,
      children: [
        {
          name: "Transaction Details",
          route: `/csms/${cpoEndpoint}/financial-management/transaction-details`,
        },
        {
          name: "My Settlements",
          route: `/csms/${cpoEndpoint}/financial-management/my-settlements`,
        },
        {
          name: "Tax Management",
          route: `/csms/${cpoEndpoint}/financial-management/tax-management`,
        },
        {
          name: "Multi-party Revenue Mgmt",
          route: `/csms/${cpoEndpoint}/financial-management/multi-party-revenue-management`,
        },
      ],
    },
    {
      name: "Performance & Analytics",
      icon: AnalyticsIcon ,
      route: `/csms/${cpoEndpoint}/performance-analytics`,
    },
    {
      name: "Integration",
      icon: IntegrationInstructionsIcon,
      route: `/csms/${cpoEndpoint}/integration`,
    },
    {
      name: "Settings",
      icon: SettingsIcon ,
      route: `/csms/${cpoEndpoint}/settings`,
    },
    {
      name: "Help and Support",
      icon: HelpSupportIcon ,
      route: `/csms/${cpoEndpoint}/support`,
    },
  ];

  return sidebarItems; // Return the sidebar items
};

export default SiteHostAdminSidebarItems;
