import React from "react";
import { Typography, Grid, TextField, Button } from "@mui/material";
import { Card } from "../atoms/card";
import Select from "react-select";
import { UploadFile } from "@mui/icons-material";

const QUERY_TYPE_OPTIONS = [
  { value: "technical_issue", label: "Technical Issue" },
  { value: "billing_issue", label: "Billing Issue" },
  { value: "account_issue", label: "Account Issue" },
  { value: "general_query", label: "General Query" },
];


function CustomerQuery() {
  const cpoEmail = localStorage?.userDetails?.email || sessionStorage?.userDetails?.email;
  return (
    <div style={{ padding: "0.5rem" }}>
      <Card xs={12} md={12} padding="1rem" boxShadow={3} borderRadius="12px">
        <Typography fontSize="20px" fontWeight="600" marginBottom="1rem" color="text.primary">
          Help Us Resolve Your Query
        </Typography>

        {/* Email Section */}
        <Grid container marginBottom="1rem" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" fontSize="16px">
              Email Address
            </Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              type="email"
              value={cpoEmail}
              InputProps={{
                sx: {
                  borderRadius: "8px",
                  fontSize: "14px",
                  height: "40px",
                },
              }}

            />
          </Grid>
        </Grid>

        {/* Query Type Section */}
        <Grid container marginBottom="1.5rem" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" fontSize="16px">
              Query Type
            </Typography>
            <Select
              className="select-component"
              classNamePrefix="select"
              isSearchable
              name="query_type"
              options={QUERY_TYPE_OPTIONS}
              placeholder="Select Query Type"
            />
          </Grid>
        </Grid>


        {/* Description Section */}
        <Grid container marginBottom="1.5rem" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" fontSize="16px">
              Description
            </Typography>
            <TextField
              fullWidth
              id="query-description"
              name="query-description"
              placeholder="Describe your issue"
              multiline
              rows={4}
              InputProps={{
                sx: {
                  borderRadius: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* Screenshot Upload Section */}
        <Grid container marginBottom="1.5rem" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600" fontSize="16px">
              Attach Screenshots (optional)
            </Typography>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFile />}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                padding: "0.5rem 1rem",
              }}
            >
              Upload Files
              <input hidden accept="image/*" multiple type="file" />
            </Button>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Grid textAlign="right">
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ borderRadius: "8px", textTransform: "none", padding: "0.75rem 2rem" }}
          >
            Raise Ticket
          </Button>
        </Grid>
      </Card>
    </div>
  );
}

export default CustomerQuery;
