import React from 'react'
import { Card, Typography, Grid,  } from '@mui/material'
import NumberChip from "../atoms/numberChip";
import AvailableIcon from "../icons/availableIcon";
import { ChevronRight } from "@mui/icons-material";
import ChargingStationIcon from '@mui/icons-material/ChargingStation'; 

const NotificationsComponent = () => {
  return (
    <>
    <Typography
      component={"h1"}
      fontWeight={"500"}
      fontSize={"1.25rem"}
      marginBottom={"0.75rem"}
    >
      Notifications
    </Typography>
    <Grid
      overflowY={"auto"}
      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      display={"flex"}
      flexDirection={"column"}
    >
      <Grid
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
        sx={{
          background: " #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 4px 20px #FAFAFA",
          borderRadius: "20px",
          opacity: "1",
          padding: "12px 2px 12px 2px",
          mb: 1,
          px: "12px",
        }}
      >
        <Typography
          component={"div"}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography
            component={"span"}
            color={"#002F69"}
            sx={{
              background: "#F3F3F3 0% 0% no-repeat padding-box",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "1rem",
            }}
          >
            <AvailableIcon color={"#002F69"} />
          </Typography>
          <Typography
            component={"span"}
            color={"#002F69"}
            fontWeight={"600"}
            fontSize={"1rem"}
          >
            Charger Busy
            <br />
            <Typography
              fontSize={"12px"}
              fontWeight={"400"}
              color={"#002F69"}
            >
              B675-2424-DFFA-4DE9 (Changi Airport) | 19 Sept 2024 1:04 PM
            </Typography>
          </Typography>
        </Typography>
        <Typography marginLeft={"auto"}>
          <NumberChip number={<ChevronRight />} textColor={"green"} />
        </Typography>
      </Grid>
      <Grid
        xs={12}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
        sx={{
          background: " #FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: " 0px 4px 20px #FAFAFA",
          borderRadius: "20px",
          opacity: "1",
          padding: "12px 2px 12px 2px",
          px: "12px",
        }}
      >
        <Typography
          component={"div"}
          display={"flex"}
          alignItems={"center"}
        >
          <Typography
            component={"span"}
            color={"#FFFFFF"}
            sx={{
              background: "#B1F7DB 0% 0% no-repeat padding-box",
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "1rem",
            }}
          >
            <ChargingStationIcon sx={{ color: "#20C85D" }} />
          </Typography>
          <Typography
            component={"span"}
            color={"#20C85D"}
            fontWeight={"600"}
            fontSize={"1rem"}
          >
            Charger Registered
            <br />
            <Typography
              fontSize={"12px"}
              fontWeight={"400"}
              color={"black"}
            >
              A1E5-9AE4-EAF2-4598 (Changi Airport) | 19 sept 2024, 08:30 PM
            </Typography>
          </Typography>
        </Typography>
        <Typography marginLeft={"auto"}>
          <NumberChip number={<ChevronRight />} textColor={"red"} />
        </Typography>
      </Grid>
    </Grid>
  </>
  )
}

export default NotificationsComponent
