import React from "react";

const MailIcon = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6.25L8.53194 10.5366C9.06087 10.8837 9.32534 11.0573 9.61301 11.1245C9.86712 11.1839 10.1329 11.1839 10.387 11.1245C10.6747 11.0573 10.9391 10.8837 11.4681 10.5366L18 6.25M5.84 16H14.16C15.5041 16 16.1762 16 16.6896 15.7548C17.1412 15.539 17.5083 15.1948 17.7384 14.7715C18 14.2902 18 13.6601 18 12.4V7.6C18 6.33988 18 5.70982 17.7384 5.22852C17.5083 4.80516 17.1412 4.46095 16.6896 4.24524C16.1762 4 15.5041 4 14.16 4H5.84C4.49587 4 3.82381 4 3.31042 4.24524C2.85883 4.46095 2.49168 4.80516 2.26158 5.22852C2 5.70982 2 6.33988 2 7.6V12.4C2 13.6601 2 14.2902 2.26158 14.7715C2.49168 15.1948 2.85883 15.539 3.31042 15.7548C3.82381 16 4.49587 16 5.84 16Z" stroke="#1876F4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default MailIcon;