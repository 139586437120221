import React from 'react';
import HomeIcon from '../icons/homeIcon';
import ChargersIcon from '../icons/chargersIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import userMgmtIcon from '../icons/userMgmt';
import SettingsIcon from '../icons/settingsIcon';
import FinanceIcon from '../icons/Finance';

const AdminSidebarItems = [
  {
    name: "Dashboard",
    icon: HomeIcon,
    route: "/home",
  },
  {
    name: "User Management",
    icon: userMgmtIcon,
    route: "/user-management",
    children: [
      {
        name: "Chargnex Team",
        route: "/user-management/my-users",
      },
      {
        name: "Customers (CPO)",
        route: "/user-management/cpo",
      },
    ],
  },
  {
    name: "Charger Management",
    icon: ChargersIcon,
    route: "/charger-management",
    children: [
      {
        name: "EVSE Stations",
        route: "/charger-management/evse-stations",
      },
      {
        name: "Chargers",
        route: "/charger-management/chargers",
      },
      {
        name: "Updates",
        route: "/charger-management/updates",
        hasGrandchildren: true, // Indicating it should be highlighted when active
        children: [
          {
            name: "Monitor",
            route: "/charger-management/updates/monitors",
          },
          {
            name: "OTA Updates",
            route: "/charger-management/updates/ota-updates",
          },
        ],
      },
    ],
  },
  {
    name: "Financial Management",
    icon: FinanceIcon,
    route: `/financial-management`,
    children: [
      {
        name: "Transaction Details",
        route: `/financial-management/transaction-details`,
      },
      {
        name: "Settlements",
        route: `/financial-management/my-settlements`,
      },
    ],
  },
  {
    name: "Performance & Analytics",
    icon: AnalyticsIcon,
    route: "/performance-analytics",
  },
  {
    name: "Settings",
    icon: SettingsIcon,
    route: "/settings",
  }
];

export default AdminSidebarItems;
