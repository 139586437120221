import React from "react";

const ChargnexLogo = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "302.78"}
      height={height ?? "86"}
      viewBox="0 0 302.78 86"
    >
      <g id="chargnex-logo" transform="translate(0.1 0.1)">
        <path
          id="Path_4715"
          data-name="Path 4715"
          d="M182.5,50.384v6.25c0,3.323,2.057,4.984,6.25,4.984H195V45.4h-6.25C184.557,45.4,182.5,47.061,182.5,50.384Z"
          transform="translate(-38.133 -9.502)"
          fill="none"
        />
        <path
          id="Path_4716"
          data-name="Path 4716"
          d="M276.882,51.629c2.453,0,3.718-.87,3.718-2.532V47.832c0-1.661-1.187-2.532-3.718-2.532H271.9c-2.453,0-3.719.791-3.719,2.532H268.1v3.956c.791-.079,1.661-.158,2.532-.158Z"
          transform="translate(-56.009 -9.481)"
          fill="none"
        />
        <path
          id="Path_4717"
          data-name="Path 4717"
          d="M96.9,60.432V61.7c0,1.661,1.187,2.532,3.718,2.532H109.4V57.9h-8.782C98.166,57.979,96.9,58.849,96.9,60.432Z"
          transform="translate(-20.257 -12.112)"
          fill="none"
        />
        <path
          id="Path_4718"
          data-name="Path 4718"
          d="M297.341,25.8h12.1l10.285,12.659L345.206,5.7,319.731,29.752l-6.883-8.307h-26.9L306.993,45.97,279.065,81.177H275.9l4.193,5.934,4.351-5.459h.158l29.352-35.761Z"
          transform="translate(-57.638 -1.211)"
          fill="#fff"
        />
        <path
          id="Path_4719"
          data-name="Path 4719"
          d="M364.62,5.913,360.426-.1l-4.984,6.25h.237L327.751,40.17l16.615,20.1H332.182L321.9,47.607,296.5,80.362,321.976,56.31l6.883,8.307h26.9L334.713,40.091,361.851,5.913Z"
          transform="translate(-61.939 0)"
          fill="#fff"
        />
        <path
          id="Path_4720"
          data-name="Path 4720"
          d="M-.1,45.364c0,11.709,4.984,17.564,15.111,17.564H30.123V57.944H17.543q-8.782,0-8.782-12.58c0-8.307,2.927-12.58,8.782-12.58h12.58V27.8H15.011C4.963,27.8-.1,33.655-.1,45.364Z"
          transform="translate(0 -5.826)"
          fill="#fff"
        />
        <path
          id="Path_4721"
          data-name="Path 4721"
          d="M62.8,36.582H51.482V27.8H42.7V62.928h8.782V41.645h6.329c4.193,0,6.25,1.661,6.25,4.984v16.3h8.782V46.63C72.844,39.984,69.521,36.582,62.8,36.582Z"
          transform="translate(-8.938 -5.826)"
          fill="#fff"
        />
        <path
          id="Path_4722"
          data-name="Path 4722"
          d="M104.53,38.9H85.7v4.984h17.564c2.532,0,3.718.87,3.718,2.532,0,1.741-1.266,2.611-3.718,2.611h-6.25C89.5,49.027,85.7,51.48,85.7,56.385v1.266c0,4.984,3.718,7.516,11.314,7.516h18.83V47.049h0C115.844,41.59,112.125,38.9,104.53,38.9Zm2.532,21.362H98.28c-2.532,0-3.718-.87-3.718-2.532V56.464c0-1.582,1.266-2.453,3.718-2.532h8.782Z"
          transform="translate(-17.918 -8.144)"
          fill="#fff"
        />
        <path
          id="Path_4723"
          data-name="Path 4723"
          d="M148.6,38.9H128.5V65.246h8.782V43.963h6.329c4.193,0,6.25,1.661,6.25,4.984h8.782C158.644,42.3,155.321,38.9,148.6,38.9Z"
          transform="translate(-26.856 -8.144)"
          fill="#fff"
        />
        <path
          id="Path_4724"
          data-name="Path 4724"
          d="M171.4,48.948V55.2c0,6.646,3.323,10.048,10.048,10.048h11.314c-.079,3.4-2.136,5.063-6.329,5.063H176.384v4.984H191.5c6.646,0,10.048-3.323,10.048-10.048V38.9h-20.1C174.8,38.9,171.4,42.223,171.4,48.948Zm21.282-4.905V60.262h-6.25c-4.193,0-6.25-1.661-6.25-4.984v-6.25c0-3.323,2.057-4.984,6.25-4.984Z"
          transform="translate(-35.815 -8.144)"
          fill="#fff"
        />
        <path
          id="Path_4725"
          data-name="Path 4725"
          d="M234.2,38.9H214.1V65.246h8.782v-21.2h.079v-.079h6.25c4.193,0,6.25,1.661,6.25,4.984v16.3h8.782v-16.3C244.244,42.3,240.921,38.9,234.2,38.9Z"
          transform="translate(-44.732 -8.144)"
          fill="#fff"
        />
        <path
          id="Path_4726"
          data-name="Path 4726"
          d="M287.143,60.262H269.58c-2.532,0-3.719-.87-3.719-2.532s1.266-2.532,3.719-2.532h6.25c7.516,0,11.314-2.532,11.314-7.516V46.416c0-4.984-3.718-7.516-11.314-7.516h-8.782C260.4,38.9,257,41.432,257,46.416V57.1c0,5.459,3.719,8.149,11.314,8.149h18.83ZM265.782,46.5h0c.079-1.741,1.345-2.532,3.8-2.532h4.984c2.532,0,3.718.87,3.718,2.532v1.266c0,1.661-1.266,2.532-3.718,2.532h-6.25c-.87,0-1.741.079-2.532.158Z"
          transform="translate(-53.691 -8.144)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ChargnexLogo;
