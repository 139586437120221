import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  Menu,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import AdminSidebarItems from "./sidebarItemAdmin";
import EVSEOwnerSidebarItems from "./sidebarItemEvseOwner";
import cpoItems from "./sidebarItemCpo";
import styles from "./sidebar.module.scss";
import Pusher from "pusher-js";
import { WhoAmI } from "../../redux/apis/user";
import { setEvse, setCharger } from "../../redux/slices/filter/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import ChargnexLogoBlack from "../icons/chargnexLogoBlack"


const StyledLink = styled(Link)`
  text-decoration: none;
  color: #343434; /* Black text to match the theme's primary text color */
  &:hover {
    color: #333333; /* Slightly darker shade for hover effect */
  }
`;

const SidebarContent = styled.div`
  padding: 10px; /* Keeping consistent padding */
  background-color: #FFFFFF; /* White background from the theme */
  color: #343434; /* Black text for readability */
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 12px; /* Updated font size based on theme's typography */
  border-right: 1px solid #DCE5F0; /* Subtle gray border for separation */
`;

const StyledTypography = styled(Typography)`
  margin-top: 10px;
  font-size: 0.7rem; /* Matching h2 size in the theme */
  font-weight: 500; /* Consistent with the theme's secondary typography weight */
  color: #343434; /* Black color for primary text */
`;



const StyledList = styled(List)`
  padding-top: 10px;
  padding-right: 4px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SidebarArrowIcon = styled(({ active, ...props }) =>
  active ? <ExpandLess {...props} /> : <ExpandMore {...props} />
)`
  color: "#343434"; /* White for active icons, black for inactive */
`;


const Sidebar = ({ isSidebarOpen, toggleSidebar, isMobile }) => {

  const selectedTheme = useSelector((state) => state.theme);
  // Styled components
  const ResponsiveListItem = styled(ListItem)(({ active, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: active
      ? selectedTheme.primaryBlue// Dynamic active background color
      : 'transparent',
    '&:hover': {
      backgroundColor: active ? selectedTheme.primaryBlue : selectedTheme.tertiaryBlue, // Dynamic hover color for active
    },
    color: active
      ? '#FFFFFF' // Static white for active text
      : '#343434', // Static black for inactive text
  }));

  const ResponsiveListItemForDropdown = styled(ListItem)(({ active, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: active
      ? selectedTheme.primaryBlue// Dynamic active background color
      : 'transparent',
    '&:hover': {
      backgroundColor: active
        ? selectedTheme.hoverBlue // Dynamic hover color for active
        : selectedTheme.tertiaryBlue, // Static light gray for inactive hover
    },
    color: active
      ? '#FFFFFF' // Static white for active text
      : '#343434', // Static black for inactive text
  }));
  const [deviceManagementOpen, setDeviceManagementOpen] = useState(false);
  const [chargerMgmtOpen, setChargerMgmtOpen] = useState(false);
  const [financialMgmtOpen, setFinancialMgmtOpen] = useState(false);
  const [userMgmtOpen, setUserMgmtOpen] = useState(false);
  const location = useLocation();
  const userDetails = JSON.parse(localStorage.getItem(`userDetails`) || sessionStorage.getItem(`userDetails`));
  const userRole = userDetails?.category;
  const dispatch = useDispatch();
  const cpoDetails = (userRole === "CPO Admin") ? JSON.parse(localStorage.getItem(`cpoDetails`) || sessionStorage.getItem(`cpoDetails`)) : "";
  const eMspDetails = (userRole === "Super Admin") ? JSON.parse(localStorage.getItem(`eMspDetails`) || sessionStorage.getItem(`eMspDetails`)) : "";
  const [Logo, setLogo] = useState("");
  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";


  const [logoLoaded, setLogoLoaded] = useState(true);

  const handleLogoLoad = () => {
    setLogoLoaded(true);
  };

  const handleLogoError = () => {
    setLogoLoaded(false);
  };
  // Fetch on Profile Picture Update

  useEffect(() => {

    const whoami = async () => {
      try {
        const response = await WhoAmI();
        if (response?.settings?.cpo?.profilePicture) {
          setLogo(response?.settings?.cpo?.profilePicture);
        }
        else if (response?.settings?.eMsp?.profilePicture) {
          setLogo(response?.settings?.eMsp?.profilePicture);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    whoami();
  }, []);

  useEffect(() => {

    if (location.pathname !== "/device-management/monitor") {
      dispatch(setEvse(""));
      dispatch(setCharger(""));
    }

  }, [location.pathname, dispatch]);

  useEffect(() => {

    if (userRole !== "CPO Admin" || !cpoDetails?.id) return;
    // Initialize Pusher
    setLogo("");

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(cpoDetails?.id);

    // Bind to the event
    channel.bind(`account-updated`, (data) => {
      const updatedLogo = `${data.profilePicture}?v=${new Date().getTime()}`;
      console.log(updatedLogo); // For debugging, logs the updated URL
      setLogo(updatedLogo);

    });

    // Clean up subscription on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };

  }, []);

  useEffect(() => {

    if (userRole !== "Super Admin" || !eMspDetails?.id) return;
    // Initialize Pusher
    setLogo("");

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(eMspDetails?.id);

    // Bind to the event
    channel.bind(`account-updated`, (data) => {
      const updatedLogo = `${data.profilePicture}?v=${new Date().getTime()}`;
      console.log(updatedLogo); // For debugging, logs the updated URL
      setLogo(updatedLogo);
    });

    // Clean up subscription on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };

  }, []);

  const CpoSidebarItems = cpoItems();
  let sidebarItems = [];
  switch (userRole) {
    case "Super Admin":
      sidebarItems = AdminSidebarItems;
      break;
    case "factory_manager":
      sidebarItems = EVSEOwnerSidebarItems;
      break;
    case "CPO Admin":
      sidebarItems = CpoSidebarItems;
      break;
    default:
      break;
  }

  useEffect(() => {
    // Close dropdowns when the location changes
    if (location?.pathname?.includes("device-management")) {
      setDeviceManagementOpen(true);
    } else {
      setDeviceManagementOpen(false);
    }

    if (location?.pathname?.includes(`/charger-management`)) {
      setChargerMgmtOpen(true);
    } else {
      setChargerMgmtOpen(false);
    }

    if (location?.pathname?.includes(`/financial-management`)) {
      setFinancialMgmtOpen(true);
    } else {
      setFinancialMgmtOpen(false);
    }

    if (location?.pathname?.includes(`/user-management`)) {
      setUserMgmtOpen(true);
    } else {
      setUserMgmtOpen(false);
    }
  }, [location.pathname]);

  const toggleDeviceManagement = (e) => {
    e.preventDefault();
    setDeviceManagementOpen((prevOpen) => !prevOpen);
  };

  const toggleChargerMgmt = (e) => {
    e.preventDefault();
    setChargerMgmtOpen((prevOpen) => !prevOpen);
  };

  const toggleFinancialMgmt = (e) => {
    e.preventDefault();
    setFinancialMgmtOpen((prevOpen) => !prevOpen);
  };

  const toggleUserMgmt = (e) => {
    e.preventDefault();
    setUserMgmtOpen((prevOpen) => !prevOpen);
  };

  const renderSidebarItem = (item) => {
    const { name, icon: Icon, route, children } = item;
    const isActive = location.pathname === route;

    if (name === "Device Mgmt") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleDeviceManagement}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1, background: deviceManagementOpen ? `${selectedTheme.tertiaryBlue} !important` : "transparent" }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{<Icon active={"#343434"} />}</ListItemIcon>
              <ListItemText primary={name} sx={{ color: "#343434" }} />
              <SidebarArrowIcon
                active={deviceManagementOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {deviceManagementOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItemForDropdown
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1, }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItemForDropdown>
              </StyledLink>
            ))}
        </div>
      );
    }

    if (name === "User Management") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleUserMgmt}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1, color: isActive ? "#1876F4" : "#4B98FF", background: userMgmtOpen ? `${selectedTheme.tertiaryBlue} !important` : "transparent" }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{<Icon active={"#343434"} />}</ListItemIcon>
              <ListItemText primary={name} sx={{ color: "#343434" }} />
              <SidebarArrowIcon
                active={userMgmtOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {userMgmtOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItemForDropdown
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItemForDropdown>
              </StyledLink>
            ))}
        </div>
      );
    }

    if (name === "Charger Management") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleChargerMgmt}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1, background: chargerMgmtOpen ? `${selectedTheme.tertiaryBlue} !important` : "transparent" }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{<Icon active={"#343434"} />}</ListItemIcon>
              <ListItemText primary={name} sx={{ color: "#343434" }} />
              <SidebarArrowIcon
                active={chargerMgmtOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {chargerMgmtOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItemForDropdown
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItemForDropdown>
              </StyledLink>
            ))}
        </div>
      );
    }

    if (name === "Financial Management") {
      return (
        <div key={route}>
          <StyledLink to={route} onClick={toggleFinancialMgmt}>
            <ResponsiveListItem
              button
              active={isActive}
              sx={{ borderRadius: "14px", mb: 1, background: financialMgmtOpen ? `${selectedTheme.tertiaryBlue} !important` : "transparent" }}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>{<Icon active={"#343434"} />}</ListItemIcon>
              <ListItemText primary={name} sx={{ color: "#343434" }} />
              <SidebarArrowIcon
                active={financialMgmtOpen}
                fontSize="small"
              />
            </ResponsiveListItem>
          </StyledLink>
          {financialMgmtOpen &&
            children.map((child) => (
              <StyledLink to={child.route} key={child.route}>
                <ResponsiveListItemForDropdown
                  button
                  active={location.pathname === child.route}
                  sx={{ borderRadius: "14px", mb: 1 }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {child.icon}
                  </ListItemIcon>
                  <ListItemText primary={child.name} />
                  {location.pathname === child.route && (
                    <ChevronRight
                      active={location.pathname === child.route}
                      fontSize="small"
                    />
                  )}
                </ResponsiveListItemForDropdown>
              </StyledLink>
            ))}
        </div>
      );
    }

    return (
      <StyledLink to={route} key={route}>
        <ResponsiveListItem
          button
          active={isActive}
          sx={{ borderRadius: "14px", mb: 1 }}
        >
          <ListItemIcon sx={{ minWidth: "30px" }}>{<Icon active={isActive ? "#FFFFFF" : "#343434"} />}</ListItemIcon>
          <ListItemText primary={name} />
          {isActive && <ChevronRight active={isActive} fontSize="small" />}
        </ResponsiveListItem>
      </StyledLink>
    );
  };

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
          >
            <Menu />
          </IconButton>
          <Drawer
            variant="persistent"
            anchor="left"
            open={isSidebarOpen}
          >
            <SidebarContent>
              <StyledTypography component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px" }}>
                {/* Conditionally render with smooth transition */}
                <img
                  src={Logo}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    maxHeight: "50px",
                    backgroundColor: "transparent",
                    opacity: logoLoaded ? 1 : 0, // Fade in the image if loaded
                    transition: "opacity 0.3s ease", // Smooth transition
                    position: logoLoaded ? "relative" : "absolute", // Prevent fallback logo from shifting
                  }}
                  alt="Chargnex"
                  onLoad={handleLogoLoad} // Set to true when the logo is loaded
                  onError={handleLogoError} // Set to false if logo fails to load
                />
                {/* Show fallback logo with smooth transition */}
                <ChargnexLogoBlack
                  sx={{
                    opacity: logoLoaded ? 0 : 1, // Fallback logo fades in when image is not loaded
                    transition: "opacity 0.3s ease", // Smooth transition for opacity
                    position: logoLoaded ? "absolute" : "relative", // Prevent fallback logo from shifting
                  }}
                />
              </StyledTypography>
              <StyledList>
                {sidebarItems.map((item) => (
                  <React.Fragment key={item.name}>
                    {renderSidebarItem(item)}
                  </React.Fragment>
                ))}
              </StyledList>
            </SidebarContent>
          </Drawer>
        </>
      ) : (
        <SidebarContent>
          <StyledTypography component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px", position: "relative" }}>
            {/* Always render both, but control visibility */}
            <img
              src={Logo}
              style={{
                objectFit: "contain",
                width: "100%",
                maxHeight: "50px",
                backgroundColor: "transparent",
                opacity: logoLoaded ? 1 : 0, // Fade in the image if loaded
                visibility: logoLoaded ? "visible" : "hidden", // Ensure the image is only visible when loaded
                transition: "opacity 0.3s ease, visibility 0s 0.3s", // Smooth fade-in and no delay on visibility change
                position: "absolute", // Position image absolutely
              }}
              onLoad={handleLogoLoad} // Set to true when the logo is loaded
              onError={handleLogoError} // Set to false if logo fails to load
            />
            {/* Show fallback logo with smooth transition */}
            <ChargnexLogoBlack
              sx={{
                opacity: logoLoaded ? 0 : 1, // Fallback logo fades in when image is not loaded
                visibility: logoLoaded ? "hidden" : "visible", // Ensure fallback logo is visible when image is not loaded
                transition: "opacity 0.3s ease, visibility 0s 0.3s", // Smooth fade-in and no delay on visibility change
                position: "absolute", // Position fallback logo absolutely
              }}
            />
          </StyledTypography>
          <StyledList>
            {sidebarItems.map((item) => (
              <React.Fragment key={item.name}>
                {renderSidebarItem(item)}
              </React.Fragment>
            ))}
          </StyledList>
        </SidebarContent>
      )}
    </>
  );
};

export default Sidebar;
