import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { InputAdornment } from "@mui/material";
import MailIcon from "../../components/icons/mailIcon";
import AuthLayout from "../../layout/authLayout";
import ChargnexLogo from "../../components/icons/chargnexLogo";
import ChargnexBackgroundLogo from "../../components/icons/chargnexBackgroundLogo";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { message } from 'antd';
import { useDispatch } from "react-redux";
import { updateCpoEndpoint } from "../../redux/slices/user/cpoSlice";
import { useTheme } from "@mui/styles";

export default function SignIn() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const authapi = `${process.env.REACT_APP_BASE_API_URL}/api/auth/login`;

  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const { state } = location;
  const checkemail = state ? state.email : "";
  const rememberMeFromLocation = state ? state.rememberMe : false;

  useEffect(() => {
    if (checkemail) {
      setEmail(checkemail);
    }
    if (rememberMeFromLocation) {
      setRememberMe(rememberMeFromLocation);
    }
  }, [checkemail, rememberMeFromLocation]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev); // Toggle password visibility
  };

  const handleErrors = () => {
    let newErrors = {};
    if (!email) {
      newErrors.email = "Email is required";
    }
    if (!password) {
      newErrors.password = "Password is required";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;

  }

  const onSubmit = async (event) => {
    if(!handleErrors()) return

    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(authapi, {
        email,
        password,
      });

      const { user, session } = response.data;
      const userDetails = {
        firstName: response.data.user.firstName,
        lastName: response.data.user.lastName,
        email: response.data.user.email,
        mobile: response.data.user.phoneNumber,
        category: response.data.userRole,
      };

      const cpoDetails = response.data?.settings?.cpo;
      const eMspDetails = response.data?.settings?.eMsp;
      dispatch(updateCpoEndpoint(cpoDetails?.endpoint));

      const userKey = `user_${user.id}`;
      const tokenKey = `token_${user.id}`;
      const rememberMeKey = `rememberMe_${user.id}`;

      if (rememberMe) {
        localStorage.setItem('currentUser', user.id);
        localStorage.setItem(userKey, user.id);
        localStorage.setItem(tokenKey, session.token);
        localStorage.setItem(rememberMeKey, true);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        localStorage.setItem('userRole', userDetails.category);
        localStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
        localStorage.setItem('eMspDetails', JSON.stringify(eMspDetails));
      } else {
        sessionStorage.setItem('currentUser', user.id);
        sessionStorage.setItem(userKey, user.id);
        sessionStorage.setItem(tokenKey, session.token);
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        sessionStorage.setItem('userRole', userDetails.category);
        sessionStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
        sessionStorage.setItem('eMspDetails', JSON.stringify(eMspDetails));
      }
    
      console.log(cpoDetails?.endpoint || cpoDetails?.endpoint)
      if (userDetails.category === "CPO Admin") {
        if (cpoDetails.endpoint === null || cpoDetails.endpoint === undefined) {
          // Redirect to settings if endpoint is null or undefined
          window.location.href = `/csms/${cpoDetails?.endpoint || "cpo"}/settings`;
          return; // Exit early after redirect
        } else {
          // Redirect to home if endpoint is not null
          window.location.href = `/csms/${cpoDetails?.endpoint || "cpo"}/home`;
          return;
        }
      } else if (userDetails.category === "factory_manager") {
        // Redirect factory managers to home
        window.location.href = "/home";
      } else {
        // Default redirect to home for other users
        window.location.href = "/home";
      }
      

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === "User not found.") {
          message.error("User Not Found.");
        } else if (error.response.data.message === "Invalid password.") {
          message.error("Invalid Password.");
        }
      } else {
        console.error("Login error:", error);
        message.error("An Unexpected Error Occurred.");
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </div>
      ) : (
        <AuthLayout>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            sx={{
              alignItems: "stretch",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#1876F4",
                borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
                padding: "40px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ChargnexLogo  />
              <Typography component="div" sx={{ position: "absolute" }}>
                <ChargnexBackgroundLogo />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: "40px",
                borderRadius: "19px",
                backgroundColor: "#ffffff",
                maxWidth: "400px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                fontWeight="500"
                fontSize="30px"
                marginBottom="20px"
                textAlign="center"
              >
                Sign in
              </Typography>
              <form onSubmit={onSubmit} noValidate>
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{ marginBottom: "20px" }}
                  id="email"
                  label="Your Email"
                  name="email"
                  autoComplete="email"
                  error={errors.email}
                  helperText={errors.email}
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailIcon />
                      </InputAdornment>
                    ),
                    sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
                  }}
                  InputLabelProps={{ sx: { fontSize: "14px" } }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{ marginBottom: "20px" }}
                  id="password"
                  label="Password"
                  name="password"
                  error={errors.password}
                  helperText={errors.password}
                  type={showPassword ? "text" : "password"} // Toggle between text and password
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={togglePasswordVisibility} sx={{ padding: 0, minWidth: "0" }}>
                          {showPassword ? <VisibilityOff sx={{ color: theme.palette.iconColor.main }} /> : <Visibility sx={{ color: theme.palette.iconColor.main }} />}
                        </Button>
                      </InputAdornment>
                    ),
                    sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
                  }}
                  InputLabelProps={{ sx: { fontSize: "14px" } }}
                />
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ marginBottom: "20px" }}
                >
                  <Grid item xs>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                          value="remember"
                          color="primary"
                        />
                      }
                      sx={{ height: "21px" }}
                      label="Remember me"
                    />
                  </Grid>
                  <Grid item xs textAlign="right" color="secondary.main">
                    <Typography component="span"><Link to="/forgot-password">Forgot Password?</Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    marginBottom: "20px",
                    color: "white",
                    borderRadius: "14px",
                    height: "48px",
                  }}
                >
                  Continue
                </Button>
              </form>
            </Box>
          </Box>
        </AuthLayout>
      )}
    </>
  );
}
