import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../../layout/authLayout';
import ChargnexLogo from '../../components/icons/chargnexLogo';
import ChargnexBackgroundLogo from '../../components/icons/chargnexBackgroundLogo';
import MailIcon from '../../components/icons/mailIcon';
import { InputAdornment } from '@mui/material';
import axios from 'axios';
import { message } from 'antd';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [msg, setmsg] = useState('');
  const navigate = useNavigate();
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const path = 'Forgot Password';

  const [errors, setErrors] = useState({});

  const handleErrors = () => {
    let newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    }

    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.emailfomrat = 'Invalid email format';
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {

    event.preventDefault(); // Prevent default form submission behavior

    // Validate the form
    const isValid = handleErrors();
    if (!isValid) {
      return;
    }
    if(isValid){
      setErrors({});
    }

    try {
      await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/forgot-password`, { email });
      message.success('Email Sent Successfully');
      navigate('/set-password', { state: { email, path } })
    } catch (error) {
      setmsg('An Error Occurred. Please Try Again.');
      message.error(error.response.data.message);
    }
  };

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#1876F4",
            borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
            padding: "40px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChargnexLogo width={undefined} height={undefined} />
          <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
            <ChargnexBackgroundLogo />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "40px",
            borderRadius: "19px",
            backgroundColor: "#ffffff",
            maxWidth: "400px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            fontWeight="500"
            fontSize="30px"
            marginBottom="20px"
            textAlign="center"
          >
            Forgot Password
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="email"
              label="Your Email"
              name="email"
              autoComplete="email"
              value={email}
              error={errors.email || errors.emailfomrat}
              helperText={errors.email || errors.emailfomrat}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginBottom: "20px",
                color: "white",
                borderRadius: "14px",
                height: "48px",
              }}
            >
              Send Code
            </Button>
            {msg && <Typography marginTop="20px">{msg}</Typography>}
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ForgotPassword;
