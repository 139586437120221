import axios, { axiosInstance } from "axios";

const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const API = (force = false) => {
  if (axiosInstance && !force) {
    return axiosInstance;
  }

  axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  });

  return axiosInstance;
};

const fetch = async (config) => {
  try {
    const response = await API().request(config);
    return response?.data;
  } catch (e) {
    if (
      e?.error?.response &&
      e?.error?.response?.data &&
      e?.error?.response?.data?.message
    ) {
      throw new Error(
        e.error.response.data.message || "Bad response from server"
      );
    } else {
      throw new Error(e?.error?.message || "Bad response from server");
    }
  }
};

const formatTime = (dateTimeString) => {
  if (!dateTimeString) return "";

  const timePart = dateTimeString.split("T")[1]?.slice(0, 5); // Extract hh:mm
  if (!timePart) return "";

  const [hour, minute] = timePart.split(":").map(Number);
  const formattedHour = (hour % 12) || 12; // Convert 24-hour format to 12-hour
  const ampm = hour >= 12 ? "PM" : "AM";

  return `${formattedHour}:${minute.toString().padStart(2, "0")} ${ampm}`;
};

const formatDateTime = (localDateTimeString) => {
  const [datePart, timePartWithOffset] = localDateTimeString.split("T");
  const [year, month, day] = datePart.split("-");
  const [timePart] = timePartWithOffset.split("."); // Removes milliseconds and offset

  // Extract time components
  let [hours, minutes] = timePart.split(":").map(Number);

  // Determine AM/PM
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 24hr format to 12hr format

  // Month abbreviation
  const monthAbbreviations = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Combine and return formatted string
  return `${day} ${monthAbbreviations[parseInt(month) - 1]} ${year}, ${hours}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;
}

const handleCamelCase = (str) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};



export { deepClone, fetch, formatTime, formatDateTime , handleCamelCase};
