import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import {
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

function Customers() {
  const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    // Sample data
    const rows = [
        // Add more rows as needed
    ];

  return (
    <MainLayout >

      
        <TableContainer
          >
                <Table stickyHeader>
                <TableHead >
                        <TableRow>
                            <TableCell align="center">Customer ID</TableCell>
                            <TableCell align="center">Customer Name</TableCell>
                            <TableCell align="center">Email Address</TableCell>
                            <TableCell align="center">Phone Number</TableCell>
                            <TableCell align="center">Account Status</TableCell>
                            <TableCell align="center">Registered Vehicle Detail</TableCell>
                            <TableCell align="center">Total Charging Sessions</TableCell>
                            <TableCell align="center">Total Energy Consumed (kWh)</TableCell>
                            <TableCell align="center">Preferred Payment Method</TableCell>
                            <TableCell align="center">Last Charging Session</TableCell>
                            <TableCell align="center">Total Amount Spent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                      >
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{row.customerId}</TableCell>
                                    <TableCell align="center">{row.customerName}</TableCell>
                                    <TableCell align="center">{row.email}</TableCell>
                                    <TableCell align="center">{row.phone}</TableCell>
                                    <TableCell align="center">{row.accountStatus}</TableCell>
                                    <TableCell align="center">{row.vehicleDetail}</TableCell>
                                    <TableCell align="center">{row.chargingSessions}</TableCell>
                                    <TableCell align="center">{row.energyConsumed} kWh</TableCell>
                                    <TableCell align="center">{row.paymentMethod}</TableCell>
                                    <TableCell align="center">{row.lastSession}</TableCell>
                                    <TableCell align="center">{row.totalSpent}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
    </MainLayout>
  );
}

export default Customers;
