import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import MailIcon from "../../components/icons/mailIcon";
import AuthLayout from "../../layout/authLayout";
import ChargnexLogo from "../../components/icons/chargnexLogo";
import ChargnexBackgroundLogo from "../../components/icons/chargnexBackgroundLogo";
import { useDispatch } from "react-redux";
import { message } from "antd"; // Import Ant Design message component
import axios from "axios";

export default function ConfimLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = axios.create();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(""); // Reset error message on input change
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate email
    if (!email) {
      setEmailError("Email is required.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/check-login`,
        { email }
      );

      const { email: userEmail, isPasswordSet } = response.data;

      if (isPasswordSet) {
        navigate("/login", { state: { email: userEmail, rememberMe } });
      } else {
        navigate("/set-password", { state: { email: userEmail, rememberMe } });
      }
    } catch (error) {
      console.error("API Error:", error);
      if (error.response && error.response.status === 400) {
        message.error("User Not Found."); // Display message for user not found
      }
    }
  };

  return (
    <>
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
          
        }}
      >
        <Box
          sx={{
            backgroundColor: "#1876F4",
            borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
            padding: "40px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChargnexLogo  />
          <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
            <ChargnexBackgroundLogo />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "40px",
            borderRadius: "19px",
            backgroundColor: "#ffffff",
            maxWidth: "400px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            fontWeight="500"
            fontSize="30px"
            marginBottom="20px"
            textAlign="center"
          >
            Sign in
          </Typography>
          <form component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              margin="normal"
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="email"
              label="Your Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={!!emailError} // Highlight input in red if there's an error
              helperText={emailError} // Display error message below input
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "48px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
            
            <Grid
              container
              justifyContent="space-between"
              sx={{ marginBottom: "20px", minWidth: "300px" }}
            >
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                      value="remember"
                      color="primary"
                    />
                  }
                  sx={{ height: "16px", display: "flex", alignItems: "center" }}
                  label="Remember me"
                />
              </Grid>
              <Grid item xs textAlign="right" color="secondary.main">
                <Typography component="span">
                  <Link to="/forgot-password" sx={{fontSize:"14px"}}>Forgot Password?</Link>
                </Typography>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginBottom: "20px",
                color: "white",
                borderRadius: "14px",
                height: "48px",
              }}
            >
              Continue
            </Button>
          </form>
        </Box>
      </Box>
    </AuthLayout>
    </>
  );
}
