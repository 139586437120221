import React, { useEffect, useState } from "react";
import CardStyles from "../../components/atoms/card/index.module.scss";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonComponent from "../atoms/button/button";
import { fetchRoles, deleteRole, updateRole, AddRole } from "../../redux/apis/emsp";
import { message } from "antd";
import ModalComponent from "../atoms/ModalComponent";
import { useTheme } from '@mui/material/styles';

const Roles = () => {
  const theme = useTheme();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null); // Holds role object for editing
  const [customRole, setCustomRole] = useState(""); // For adding a new role
  const [permissions, setPermissions] = useState({});
  const [availablePermissions, setAvailablePermissions] = useState([]); // Dynamically fetched permissions
  const [openAddRoleModal, setOpenAddRoleModal] = useState(false); // State for Add Role Modal
  const [openEditRoleModal, setOpenEditRoleModal] = useState(false); // State for Edit Role Modal
  const [loading, setLoading] = useState(false); // Loading state for spinner
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [errors, setErrors] = useState({}); // State to track form errors
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete dialog, ] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  // Fetch roles from API
  const getRoles = async (page = 1) => {
    setLoading(true); // Show loading spinner when fetching data
    try {
      const response = await fetchRoles({ page });
      setRoles(response.list); // Set roles returned by API
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false); // Hide loading spinner when fetching is done
    }
  };

  // Fetch Super Admin permissions to dynamically set available permissions
  const fetchCPOAdminPermissions = async () => {
    try {
      const roles = await fetchRoles();
      const adminRole = roles.list.find((role) => role.name === "Super Admin");
      if (adminRole) {
        setAvailablePermissions(Object.keys(adminRole.permissions)); // Set the available permissions based on Super Admin
      }
    } catch (error) {
      console.error("Error fetching Super Admin role:", error);
    }
  };

  useEffect(() => {
    getRoles(); // Fetch roles for the first page on component mount
    fetchCPOAdminPermissions(); // Fetch available permissions from Super Admin on component mount
  }, []);

  const handleErrors = () => {
    let newErrors = {};
    if (!customRole) {
      errors.customRole = "Role name is required";
    }
    setErrors(errors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle permission checkbox changes
  const handlePermissionChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  // Handle Add Role Submission
  const handleAddSubmit = async () => {

    if(!handleErrors()){ 
      return;
    }

    setLoading(true); // Show loading spinner during API call
    const data = {
      name: customRole,
      permissions,
    };

    try {
      await AddRole(data);
      message.success("Role Added Successfully");
      setOpenAddRoleModal(false);
      getRoles(); // Refresh roles after adding a new role
    } catch (error) {
      console.error("Error adding role:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Handle Edit Role Submission
  const handleEditSubmit = async () => {
    if(!handleErrors()) return;

    setLoading(true); // Show loading spinner during API call
    const data = {
      name: selectedRole.name,
      permissions,
    };

    try {
      await updateRole(selectedRole.id, data); // Pass role ID and data separately
      message.success("Role Updated Successfully");
      setOpenEditRoleModal(false);
      getRoles(); // Refresh roles after editing
    } catch (error) {
      console.error("Error updating role:", error);
      message.error("Failed To Update Role");
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Handle Delete Role
  const handleDeleteClick = async () => {
    setIsDeleteLoading(true); // Show loading spinner during API call
    try {
      await deleteRole(selectedRole.id);
      
      message.success("Role Deleted Successfully");
    } catch (error) {
      console.error("Error deleting role:", error);
      message.error(error.response.data.message);
    } finally {
      setIsDeleteLoading(false); // Hide loading spinner
      getRoles(); // Refresh roles after deletion
      handleCloseDeleteDialog();
    }
  };

  const handleOpenDeleteDialog = (roleId) => {
    setOpenDeleteDialog(true);
    setSelectedRole(roles.find((role) => role.id === roleId));
    
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  // Open modal for editing role
  const handleEditClick = (role) => {
    setSelectedRole(role);
    setPermissions({
      ...role.permissions,
    });
    setOpenEditRoleModal(true);
  };

  // Open modal for adding a new role
  const handleAddRoleClick = () => {
    setErrors({});
    setPermissions({});
    setCustomRole(""); // Clear custom role field
    setOpenAddRoleModal(true);
  };

  const handleCloseAddRoleModal = () => {
    setOpenAddRoleModal(false);
  };

  const handleCloseEditRoleModal = () => {
    setOpenEditRoleModal(false);
  };

  return (
    <Grid xs={12} sx={{  marginTop: "1rem" }}>
        <Grid sx={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
          <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"}>
            Roles
          </Typography>
          <ButtonComponent
            sx={{ height: "40px", width: "80px" }}
            onClick={handleAddRoleClick}
            label={"Add Role"}
            variant="contained"
          />
        </Grid>

        {/* Conditionally render either spinner or table */}
        {loading ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "200px" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <TableContainer
              component={Paper} sx={{maxHeight: "50vh"}}
            >
              <Table stickyHeader>
                <TableHead sx={{ background: "#F5F3F3", "& th": { fontWeight: "500", color: "#333" } }}>
                  <TableRow>
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  {roles.map((role, index) => (
                    <TableRow key={role.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{role.name}</TableCell>
                      <TableCell align="center">
                        {role.name !== "Super Admin" ? (
                          <IconButton onClick={() => handleEditClick(role)}>
                          <EditIcon />
                        </IconButton>
                        ) : "Default"}
                        {!role.isDefault && (
                          <IconButton onClick={() => handleOpenDeleteDialog(role.id)}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              count={roles.length}
              component="div"
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}

        {/* Add Role Modal */}
        <ModalComponent
          open={openAddRoleModal}
          handleClose={handleCloseAddRoleModal}
          headerText={"Add Role"}
          handleSubmit={handleAddSubmit}
          submitButtonLabel={"Submit"}
        >
          <TextField
            fullWidth
            label="Role Name"
            value={customRole}
            onChange={(e) => setCustomRole(e.target.value)}
            margin="normal"
            error={!!errors.customRole}
            helperText={errors.customRole}
          />
          <Typography variant="h6" marginTop="1rem">
            Permissions
          </Typography>
          {availablePermissions.map((key) => (
            <FormControlLabel
              key={key}
              control={<Checkbox checked={permissions[key]} onChange={handlePermissionChange} name={key} />}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
            />
          ))}
        </ModalComponent>

        {/* Edit Role Modal */}
        <ModalComponent
          open={openEditRoleModal}
          handleClose={handleCloseEditRoleModal}
          headerText="Edit Role"
          handleSubmit={handleEditSubmit}
          submitButtonLabel={"Update"}
        >
          <TextField
            fullWidth
            label="Role Name"
            value={selectedRole?.name}
            margin="normal"
            disabled
          />
          <Typography variant="h6" marginTop="1rem">
            Permissions
          </Typography>
          {availablePermissions.map((key) => (
            <FormControlLabel
              key={key}
              control={<Checkbox checked={permissions[key]} onChange={handlePermissionChange} name={key} />}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
            />
          ))}
        </ModalComponent>

        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Role?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} disabled={isDeleteLoading ? true : false} color="primary">
              Cancel
            </Button>
            {isDeleteLoading ? <CircularProgress sx={{color:"#05336c", maxWidth: "40px"}} /> : <Button onClick={handleDeleteClick} color="secondary">
              Delete
            </Button>}
          </DialogActions>
        </Dialog>
    </Grid>
  );
};

export default Roles;
