import { combineReducers } from "redux";

import counter from "./slices/counter";
import filter from "./slices/filter/filterSlice";
import cpo from './slices/user/cpoSlice';
import country from './slices/user/countrySlice';
import theme from  './slices/theme/themeSlice'


const rootReducer = combineReducers({
  counter,
  filter,
  cpo,
  country,
  theme
});

export default rootReducer;
