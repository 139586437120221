import React from "react";
import styles from "./index.module.scss";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export const Card = ({ title, children, ...props }) => {
  const theme = useTheme()
  return (
    <Grid className={styles.card} xs={12} md={5.9} sm={12} {...props} sx={{boxShadow: `0 0 10px 1px ${theme.palette.tertiary.main}`}}> 
      <div>{children}</div>
    </Grid>
  );
};
