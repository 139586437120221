import React, { useState } from "react";
import {
  Box,
  Chip,
  Typography,
  Button,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalComponent from "../atoms/ModalComponent";
import { useTheme } from "@mui/styles";

const generateDetailedDummyTickets = () => {
  const statuses = ["Active", "Closed"];
  const priorities = ["High", "Medium", "Low"];
  const subjects = [
    "Charger Malfunction at Station #123",
    "Request to Add New Charger",
    "Software Update Required",
    "Charger Overheating Issue",
    "Request for Maintenance Schedule",
    "Charger #456 Connectivity Issue",
    "Station #789 Charger Replacement Request",
    "Fault Reported at EVSE Station",
    "Power Supply Issue at Charger #101",
    "Station #202 General Maintenance",
  ];
  const descriptions = [
    "The charger is overheating during peak hours and shutting down unexpectedly.",
    "A new charger is required at station #234 to handle increased demand.",
    "Software update to the latest firmware version is pending for charger #567.",
    "Connectivity issue at the remote monitoring dashboard for station #456.",
    "Charger replacement request due to hardware failure.",
  ];
  return Array.from({ length: 15 }, (_, i) => ({
    id: `TKT-${200 + i}`,
    subject: subjects[i % subjects.length],
    priority: priorities[i % priorities.length],
    status: statuses[i % statuses.length],
    requestDate: `07/${11 - i}/2023, ${Math.floor(Math.random() * 12 + 1)}:${Math.floor(Math.random() * 59)
      .toString()
      .padStart(2, "0")} AM`,
    description: descriptions[i % descriptions.length],
  }));
};

const ChargerTicketsTab = () => {
  const theme = useTheme();
  const [openClosedQueries, setOpenClosedQueries] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const tickets = generateDetailedDummyTickets();
  const openTickets = tickets.filter((ticket) => ticket.status === "Active");
  const closedTickets = tickets.filter((ticket) => ticket.status === "Closed");

  const handleOpenModal = (ticket) => {
    setSelectedTicket(ticket);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const toggleClosedQueries = () => {
    setOpenClosedQueries((prev) => !prev);
  };

  const renderTicketCard = (ticket) => (
    <Box
      key={ticket.id}
      mb={2}
      p={2}
      borderRadius="8px"
      sx={{
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.tertiary.main}`,
        width: "100%",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" fontWeight="bold">
          {ticket.subject}
        </Typography>
        <Chip
          label={ticket.status.toUpperCase()}
          sx={{
            backgroundColor:
              ticket.status === "Active"
                ? theme.palette.success.main
                : theme.palette.error.main,
            color: "white",
          }}
        />
      </Box>
      <Typography variant="body2" color="textSecondary">
        Ticket #{ticket.id} • Raised on {ticket.requestDate}
      </Typography>
      <Typography variant="body2" mt={1} noWrap>
        {ticket.description}
      </Typography>
      <Button
        variant="outlined"
        sx={{ marginTop: "10px" }}
        onClick={() => handleOpenModal(ticket)}
      >
        View Details
      </Button>
    </Box>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h6">Open Queries ({openTickets.length})</Typography>
      </Box>
      {openTickets.map(renderTicketCard)}

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Closed Queries ({closedTickets.length})</Typography>
        <IconButton onClick={toggleClosedQueries}>
          {openClosedQueries ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      <Collapse in={openClosedQueries} timeout="auto">
        {closedTickets.map(renderTicketCard)}
      </Collapse>

      {selectedTicket && (
        <ModalComponent
          open={openModal}
          handleClose={handleCloseModal}
          headerText={`Ticket Details: ${selectedTicket.id}`}
          handleSubmit={handleCloseModal}
          submitButtonLabel="Close"
        >
          <Typography variant="body1">
            <strong>Subject:</strong> {selectedTicket.subject}
          </Typography>
          <Typography variant="body1">
            <strong>Priority:</strong> {selectedTicket.priority}
          </Typography>
          <Typography variant="body1">
            <strong>Status:</strong> {selectedTicket.status}
          </Typography>
          <Typography variant="body1">
            <strong>Request Date:</strong> {selectedTicket.requestDate}
          </Typography>
          <Typography variant="body1">
            <strong>Description:</strong> {selectedTicket.description}
          </Typography>
        </ModalComponent>
      )}
    </Box>
  );
};

export default ChargerTicketsTab;
