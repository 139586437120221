import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    TablePagination,
    Select,
    MenuItem,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    InputLabel,
    FormControl
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalComponent from "../../components/atoms/ModalComponent";

const dummyTaxes = [
    { id: 1, name: "GST (Goods & Services Tax) - Standard Rate", type: "SGST", rate: 18 },
    { id: 2, name: "GST0 (Tax Group)", type: "CGST", rate: 0 },
    { id: 3, name: "GST12 (Tax Group)", type: "IGST", rate: 12 },
];

const TaxComponent = () => {
    const [taxes, setTaxes] = useState(dummyTaxes);
    const [openModal, setOpenModal] = useState(false);
    const [currentTax, setCurrentTax] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleOpenModal = (tax = null) => {
        setCurrentTax(tax);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setCurrentTax(null);
    };

    const handleDeleteModal = (tax) => {
        setCurrentTax(tax);
        setDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setCurrentTax(null);
    };

    const handleDelete = () => {
        setTaxes(taxes.filter((tax) => tax.id !== currentTax.id));
        handleCloseDeleteModal();
    };

    const handleSave = (tax) => {
        if (tax.id) {
            setTaxes(taxes.map((t) => (t.id === tax.id ? tax : t)));
        } else {
            setTaxes([...taxes, { ...tax, id: taxes.length + 1 }]);
        }
        handleCloseModal();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <Typography
                component={"div"}
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <Typography variant="h5" gutterBottom>
                    Active Taxes
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                    sx={{ color: "#FFFFFF" }}
                >
                    Add Tax
                </Button>
            </Typography>

            <TableContainer
                component={Paper}
                sx={{maxHeight:"50vh"}}
            >
                <Table stickyHeader>
                    <TableHead
                    >
                        <TableRow>
                            <TableCell align="center">Tax Name</TableCell>
                            <TableCell align="center">Tax Type</TableCell>
                            <TableCell align="center">Rate</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {taxes
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((tax) => (
                                <TableRow
                                    key={tax.id}
                                    style={{
                                        backgroundColor: "#fff",
                                        borderBottom: "1px solid #e0e0e0",
                                    }}
                                >
                                    <TableCell align="center">{tax.name}</TableCell>
                                    <TableCell align="center">{tax.type}</TableCell>
                                    <TableCell align="center">{tax.rate}%</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleOpenModal(tax)}
                                        >
                                            <EditIcon sx={{ color: "#5882E6" }} />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleDeleteModal(tax)}
                                        >
                                            <DeleteIcon sx={{ color: "#DF6465" }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={taxes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ModalComponent
                open={openModal}
                handleClose={handleCloseModal}
                handleSubmit={handleSave}
                showSubmitButton={false}
            >
                <TextField
                    fullWidth
                    label="Tax Name"
                    defaultValue={currentTax?.name || ""}
                    style={{ marginBottom: "20px" }}
                />
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <InputLabel id="tax-type-label">Select Tax Type</InputLabel>
                    <Select
                        labelId="tax-type-label"
                        defaultValue={currentTax?.type || ""}
                    >
                        <MenuItem value="SGST">SGST</MenuItem>
                        <MenuItem value="CGST">CGST</MenuItem>
                        <MenuItem value="IGST">IGST</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    label="Rate"
                    type="number"
                    defaultValue={currentTax?.rate || ""}
                />
            </ModalComponent>

            <Dialog open={deleteModal} onClose={handleCloseDeleteModal}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete "{currentTax?.name}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TaxComponent;
