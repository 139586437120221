import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Chip,
} from '@mui/material';
import MainLayout from '../../layout/mainLayout';
import ModalComponent from '../../components/atoms/ModalComponent';

const generateDummyData = () =>
  Array.from({ length: 50 }, (_, index) => ({
    serialNumber: index + 1,
    transactionId: `TAX${1000 + index}`,
    date: new Date(2024, 0, 17 + (index % 5), 10, 0).toLocaleString(),
    netAmount: `$${(Math.random() * 100).toFixed(2)}`,
    taxAmount: `$${(Math.random() * 10).toFixed(2)}`,
  }));

const TaxManagement = () => {
  const [data, setData] = useState(generateDummyData());
  const [openModal, setOpenModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleViewDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTransaction(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MainLayout>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField label="Search" variant="outlined" size="small" sx={{ width: '200px' }} />
        <TextField label="Date Range" variant="outlined" size="small" sx={{ width: '200px' }} />
        <TextField label="Payment Status" variant="outlined" size="small" sx={{ width: '200px' }} />
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr No.</TableCell>
              <TableCell align="center">Transaction ID</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Tax Amount</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.serialNumber}>
                <TableCell align="center">{row.serialNumber}</TableCell>
                <TableCell align="center">{row.transactionId}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.netAmount}</TableCell>
                <TableCell align="center">{row.taxAmount}</TableCell>
                <TableCell align="center">
                  <Button variant="outlined" size="small" onClick={() => handleViewDetails(row)}>
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ModalComponent
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Transaction Details"
        showSubmitButton={false}
      >
        {selectedTransaction ? (
          <Box padding={2}>
            <Box marginBottom={3}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Transaction ID: {selectedTransaction.transactionId}
              </Typography>
              <Typography variant="h6" fontWeight="bold" color="success.main" gutterBottom>
                Net Amount: {selectedTransaction.netAmount}
              </Typography>
              <Typography variant="h6" fontWeight="bold" color="text.secondary" gutterBottom>
                Tax Amount: {selectedTransaction.taxAmount}
              </Typography>
            </Box>

            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>{selectedTransaction.date}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Net Amount</TableCell>
                    <TableCell>{selectedTransaction.netAmount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tax Amount</TableCell>
                    <TableCell>{selectedTransaction.taxAmount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </ModalComponent>
    </MainLayout>
  );
};

export default TaxManagement;
