"use client";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  DialogContentText,
  Switch // Import Switch component
} from "@mui/material";
import ButtonComponent from "../../components/atoms/button/button";
import {
  Add,
  Visibility,
  Search as SearchIcon,
  Edit,
  Delete
} from "@mui/icons-material";
import { fetchCpoAdmins, addCpoAdmin, updateCpoAdmin, deleteCpoAdmin } from "../../redux/apis/cpo";
import ModalComponent from "../../components/atoms/ModalComponent";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { message } from "antd";
import axios from "axios";
import { getCountries } from "../../redux/apis/chargers";
import PhoneNumberInput from "../../components/phoneNumber/phone";
import { updateCountryName } from "../../redux/slices/user/countrySlice";
import { useDispatch } from "react-redux";

function Cpo() {
  const [evseStations, setEvseStationsData] = useState([]);
  const [CpoData, setCpoData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalStations, setTotalStations] = useState(0);
  const [totalCpos, setTotalCpos] = useState(0);
  const [modalPage, setModalPage] = useState(0); // Modal pagination
  const [modalRowsPerPage, setModalRowsPerPage] = useState(10); // Modal pagination
  const [modalTotalStations, setModalTotalStations] = useState(0); // Modal pagination
  const [loading, setLoading] = useState(false);
  const [openSiteModal, setOpenSiteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [CpoFormData, setCpoFormData] = useState({
    cpoName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    cpoCountry: "",
    multiPartyRevenueManagement: false // Add this field for toggle
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCpo, setSelectedCpo] = useState(null);
  const [errors, setErrors] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedCpoId, setSelectedCpoId] = useState("");
  const dispatch = useDispatch();

  const handleErrors = () => {
    let newErrors = {};
    if (!CpoFormData.cpoName) {
      newErrors.cpoName = "CPO Name is required";
    }
    if (!CpoFormData.firstName) {
      newErrors.firstName = "First Name is required";
    }
    if (!CpoFormData.lastName) {
      newErrors.lastName = "Last Name is required";
    }
    if (!CpoFormData.email) {
      newErrors.email = "Email is required";
    }
    if (!CpoFormData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(CpoFormData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!CpoFormData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    }
    else if(!Number.isInteger(Number(CpoFormData.phoneNumber))){
      newErrors.phoneNumber = "Phone number must be an integer and not contain decimal values";
    }
    if (CpoFormData.phoneNumber && (CpoFormData.phoneNumber.length < 6 || CpoFormData.phoneNumber.length > 16)) {
      newErrors.phoneNumber = "Phone Number should be between 6 to 16 digits";
    }
    if (CpoFormData.phoneNumber && isNaN(CpoFormData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number should be in digits";
    }
    if (!CpoFormData.cpoCountry) {
      newErrors.cpoCountry = "CPO Country is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;

  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalChangePage = (event, newPage) => {
    setModalPage(newPage);
  };

  const handleModalChangeRowsPerPage = (event) => {
    setModalRowsPerPage(parseInt(event.target.value, 10));
    setModalPage(0);
  };

  const fetchCountries = async () => {
    
    try {
        const response = await getCountries({
            limit: 1000
        });
        setCountries(response.list);
    } catch (error) {
        console.error("Failed to fetch countries:", error);
    } 
};

useEffect(() => {
    fetchCountries();
}, []);


  const fetchStationsForAdmin = async (CpoId) => {
    setModalLoading(true);
    try {

      const response = await fetchEvseStationsAPI({
        page: modalPage + 1 ,
        limit: modalRowsPerPage ,
        filter: `{ "cpoId": "${CpoId}" }`,
      });

        setEvseStationsData(response.list || []);
        setModalTotalStations(response.totalCount || 0);
      
    } catch (error) {
      console.error("Error fetching Stations:", error);
    }
    finally {
      setModalLoading(false);
    }
  };

  useEffect(() => {
    fetchStationsForAdmin(selectedCpoId);
  }, [selectedCpoId, modalPage, modalRowsPerPage]);

  useEffect(() => {
    dispatch(updateCountryName(CpoFormData.cpoCountry));
  }, [CpoFormData.cpoCountry, dispatch]);

  const handleCloseAddModal = () => {
    setErrors({});
    setOpenAddModal(false);
    setCpoFormData({
      cpoName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      cpoCountry: "",
      multiPartyRevenueManagement: false // Reset toggle state
    });
  };

  const handleSubmitAddAdmin = async () => {
    if (!handleErrors()) {
      return;
    } else {
      try {
        setLoading(true);
        await addCpoAdmin({
          ...CpoFormData
        });
        setCpoFormData({
          cpoName: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          cpoCountry: "",
          multiPartyRevenueManagement: false // Reset toggle state
        });
        setOpenAddModal(false);
        message.success("CPO Admin Added Successfully");
        fetchCpoData();
      } catch (error) {
        console.error("Error adding CPO admin:", error);
        message.error(error.response.data.message || "Failed To Add CPO Admin");
      }
      finally {
        setLoading(false);
      }
    }


  };


  const fetchCpoData = async () => {
    setLoading(true);
    try {
      const response = await fetchCpoAdmins({
        search: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
        sort: "cpoName:asc",
      });
      setCpoData(response.list);
      setTotalCpos(response.totalCount); // Update totalCpos with totalCount from response
    } catch (error) {
      console.error("Error fetching CPO admins:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchCpoData();
  }, [searchQuery, page, rowsPerPage]);


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenSiteModal = (CpoId) => {
    setSelectedCpoId(CpoId)
    setOpenSiteModal(true);
  };


  const handleCloseSiteModal = () => {
    setOpenSiteModal(false);
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCpoFormData({ ...CpoFormData, [name]: value });
  };

  const handleToggleMultiPartyRevenueManagement = () => {
    setCpoFormData((prev) => ({
      ...prev,
      multiPartyRevenueManagement: !prev.multiPartyRevenueManagement
    }));
  };

  const handleEditModal = async () => {
    if (!handleErrors()) {
      return;
    } else {
      setLoading(true);
      try {
        await updateCpoAdmin(selectedCpo.id, CpoFormData);
        fetchCpoData();
        message.success("CPO Admin Updated Successfully");
      }
      catch (error) {
        message.error(error.response.data.message);
      } finally {
        setLoading(false);
        setOpenEditModal(false);
      }
    }

  }

  const handleCloseEditModal = () => {
    setErrors({});
    setOpenEditModal(false);
  }

  const handleOpenEditModal = (cpo) => {
    setErrors({});
    setSelectedCpo(cpo);
    setOpenEditModal(true);
    setCpoFormData({
      cpoName: cpo.cpoName,
      firstName: cpo.firstName,
      lastName: cpo.lastName,
      email: cpo.email,
      phoneNumber: cpo.phoneNumber,
      cpoCountry: cpo.cpoCountry,
      multiPartyRevenueManagement: cpo.multiPartyRevenueManagement // Set toggle state
    });
  }

  const handleOpenDeleteModal = (cpo) => {
    setSelectedCpo(cpo);
    setOpenDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  }
  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteCpoAdmin(selectedCpo.cpoId);
      fetchCpoData();
      message.success("CPO Admin Deleted Successfully");
    }
    catch (error) {
      message.error(error.response.data.message);
    }
    finally {
      setLoading(false);
      handleCloseDeleteModal();
    }
  }

  return (
    <>
      <MainLayout>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem "}
        >
          <Typography>
            <TextField
              id="search"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add />{" "}
                  <Typography component={"span"}>Add CPO Admin</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={() => {
                setErrors({});
                setCpoFormData({
                  cpoName: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  cpoCountry: "",
                  multiPartyRevenueManagement: false // Reset toggle state
                });
                setOpenAddModal(true);
              }}
            />
          </Typography>
        </Typography>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer
              component={Paper} sx={{maxHeight: "60vh"}}
            >
              <Table stickyHeader>
                <TableHead
                >
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">CPO Name</TableCell>
                    <TableCell align="center">CPO Admin Name</TableCell>
                    <TableCell align="center">Email ID</TableCell>
                    <TableCell align="center">Mobile No.</TableCell>
                    <TableCell align="center">View EVSE Stations</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  {CpoData.map((admin, index) => (
                    <TableRow key={admin.id}>
                      <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell align="center">{admin?.cpoName}</TableCell>
                      <TableCell align="center">
                        {admin?.firstName} {admin?.lastName}
                      </TableCell>
                      <TableCell align="center">{admin?.email}</TableCell>
                      <TableCell align="center">{admin?.phoneNumber}</TableCell>
                      <TableCell align="center">
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleOpenSiteModal(admin?.cpoId)}
                          style={{ color: "#007bff", textDecoration: "underline" }}
                        >
                          View Station
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleOpenEditModal(admin)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteModal(admin)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalCpos}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ModalComponent open={openSiteModal} handleClose={handleCloseSiteModal} showSubmitButton={false} headerText={"View EVSE Station"} >

              {
                modalLoading ?
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                  :
                  <TableContainer
                    component={Paper} sx={{maxHeight: "60vh"}}>
                    <Table stickyHeader>
                      <TableHead
                      >
                        <TableRow>
                          <TableCell align="center">Sr. No.</TableCell>
                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Location</TableCell>
                          <TableCell align="center">No of chargers</TableCell>
                          <TableCell align="center">Date Of Registration</TableCell>
                          <TableCell align="center">Created By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                      >
                        {evseStations.map((station, index) => (
                          <TableRow key={station.id}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{station?.name}</TableCell>
                            <TableCell align="center">{station?.address}</TableCell>
                            <TableCell align="center">{station?.chargerCount}</TableCell>
                            <TableCell align="center">{new Date(station?.createdAt).toLocaleDateString()}</TableCell>
                            <TableCell align="center">{station?.createdByUser?.FirstName} {station?.createdByUser?.LastName}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      count={modalTotalStations}
                      rowsPerPage={modalRowsPerPage}
                      page={modalPage}
                      onPageChange={handleModalChangePage}
                      onRowsPerPageChange={handleModalChangeRowsPerPage}
                    />
                  </TableContainer>
              }
            </ ModalComponent>

            <ModalComponent
              open={openAddModal}
              handleClose={handleCloseAddModal}
              headerText="Add CPO Admin"
              handleSubmit={handleSubmitAddAdmin}
              submitButtonLabel={"submit"}
            >
              {/* Form for adding a new site */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="username"
                    name="cpoName"
                    label="CPO Name"
                    error={errors.cpoName}
                    helperText={errors.cpoName}
                    value={CpoFormData.cpoName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    error={errors.firstName}
                    helperText={errors.firstName}
                    name="firstName"
                    value={CpoFormData.firstName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    error={errors.lastName}
                    helperText={errors.lastName}
                    name="lastName"
                    label="Last Name"
                    value={CpoFormData.lastName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.name} // Use the correct field for the country name
                    isOptionEqualToValue={(option, value) => option.isoCode === value.isoCode} // Compare based on country name
                    onChange={(e, value) => {
                      setCpoFormData((prev) => ({
                        ...prev,
                        cpoCountry: value ? value.isoCode : "", // Set the country's common name
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        error={errors.cpoCountry}
                        helperText={errors.cpoCountry}
                        name="cpoCountry"
                        variant="outlined"
                        fullWidth
                        required
                        InputProps={{ ...params.InputProps, style: { borderRadius: 8 } }}
                      />
                    )}
                  />

                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneNumberInput 
                    phoneNumber={CpoFormData.phoneNumber}
                    setPhoneNumber={(number) => setCpoFormData({ ...CpoFormData, phoneNumber: number })}
                    errors={errors.phoneNumber}
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    error={errors.email}
                    helperText={errors.email}
                    label="Email"
                    value={CpoFormData.email}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Typography>Enable Multi-Party Revenue Management</Typography>
                  <Switch
                    checked={CpoFormData.multiPartyRevenueManagement}
                    onChange={handleToggleMultiPartyRevenueManagement}
                    color="primary"
                  />
                </Grid>
                
              </Grid>
            </ModalComponent>

            <ModalComponent
              open={openEditModal}
              handleClose={handleCloseEditModal}
              headerText="Update CPO Admin"
              handleSubmit={handleEditModal}
              submitButtonLabel={"submit"}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="username"
                    name="cpoName"
                    error={errors.cpoName}
                    helperText={errors.cpoName}
                    label="CPO Name"
                    value={CpoFormData.cpoName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    error={errors.firstName}
                    helperText={errors.firstName}
                    name="firstName"
                    value={CpoFormData.firstName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    error={errors.lastName}
                    helperText={errors.lastName}
                    label="Last Name"
                    value={CpoFormData.lastName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    error={errors.email}
                    helperText={errors.email}
                    label="Email"
                    value={CpoFormData.email}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <PhoneNumberInput 
                    phoneNumber={CpoFormData.phoneNumber}
                    setPhoneNumber={(number) => setCpoFormData({ ...CpoFormData, phoneNumber: number })}
                    errors={errors.phoneNumber}
                    defaultCountryCode={CpoFormData.cpoCountry}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.name} // Use the correct field for the country name
                    isOptionEqualToValue={(option, value) => option.isoCode === value.isoCode} // Compare based on country name 
                    value={countries.find((country) => country.isoCode === CpoFormData.cpoCountry) || null} // Set the current country based on formData
                    onChange={(e, value) => {
                      setCpoFormData((prev) => ({
                        ...prev,
                        cpoCountry: value ? value.isoCode : "", // Set the selected country's name
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"                                     
                        error={errors.cpoCountry}
                        helperText={errors.cpoCountry}
                        name="cpoCountry"
                        variant="outlined"
                        fullWidth
                        required
                        InputProps={{ ...params.InputProps, style: { borderRadius: 8 } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography>Enable Multi-Party Revenue Management</Typography>
                  <Switch
                    checked={CpoFormData.multiPartyRevenueManagement}
                    onChange={handleToggleMultiPartyRevenueManagement}
                    color="primary"
                  />
                </Grid>

              </Grid>
            </ModalComponent>

            <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this CPO?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteModal} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDelete} color="secondary">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </MainLayout>
    </>
  );
}

export default Cpo;