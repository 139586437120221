import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import CpoAccountSettings from "../../components/settingsComponents/cpoAccountSettings";
import MyProfile from "../../components/settingsComponents/cpomyProfile";
import Roles from "../../components/settingsComponents/cporoles";
import Tax from "../../components/settingsComponents/cpoTax";
import SuperAdminSettings from "../../components/settingsComponents/eMSPSettings";
import SuperAdminMyProfile from "../../components/settingsComponents/eMSPmyProfile";
import SuperAdminRoles from "../../components/settingsComponents/eMSPRoles";


function Settings() {
  const [activeSettingTab, setSelectedActiveSettingTab] = useState("");
  const storedDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const userDetails = storedDetails && JSON.parse(storedDetails);
  const userId = localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser');
  const category = userDetails && userDetails.category;

  useEffect(() => {
    if (category == "Super Admin") {
      setSelectedActiveSettingTab("super_admin_settings");
    } else if(category === "CPO Admin") {
      setSelectedActiveSettingTab("cpo_account_settings");
    }else{
      setSelectedActiveSettingTab("my_profile");
    }
  }, [category, setSelectedActiveSettingTab]); 

  return (
    <MainLayout setActiveTab={setSelectedActiveSettingTab}>

      {
        (userDetails.category === "CPO Admin") && ( 
        <>
            {activeSettingTab === "my_profile" && <MyProfile />}
            {activeSettingTab === "cpo_account_settings" && <CpoAccountSettings />}
            {activeSettingTab === "roles" && <Roles />}
            {activeSettingTab === "tax" && <Tax />}
        </>
        ) 
      }
      {(userDetails.category === "Super Admin") && (
          <>
            {activeSettingTab === "my_profile_super_admin" && <SuperAdminMyProfile />}
            {activeSettingTab === "super_admin_settings" && <SuperAdminSettings />}
            {activeSettingTab === "roles_super_admin" && <SuperAdminRoles />}
          </>
      )}

      {
        (userDetails.category === "factory_manager") && (
          <>
            {activeSettingTab === "my_profile" && <MyProfile />}
          </>
        )
      }
         
    </MainLayout>
  );
}

export default Settings;
