// src/redux/slices/themeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  primaryBlue: '#1876F4', // Default blue color
  hoverBlue: '#4b98ff', // Default hover color
  activeTabBlue: '#1876F4', // Default active tab blue
  tertiaryBlue: '#EBF0F7'
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateBlueColors: (state, action) => {
      return { ...state, ...action.payload }; // Merge updated colors
    },
  },
});

export const { updateBlueColors } = themeSlice.actions;
export default themeSlice.reducer;
