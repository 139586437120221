import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import SettlementsTab from './settlementTabs/SettlementsTab';
import MainLayout from '../../layout/mainLayout';

const SuperAdminSettlementsPage = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <MainLayout>
            
            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab label="Settlements List" />
            </Tabs>

            <Box sx={{ marginTop: '20px' }}>
                {activeTab === 0 && <SettlementsTab />}
            </Box>
        </MainLayout>
    );
};

export default SuperAdminSettlementsPage;
