import React, { useEffect, useState } from "react";
import dashboardStyles from "../../pages/dashboard.module.scss";
import { Typography, Grid, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, DialogActions, Button, Paper } from "@mui/material";
import AvailableIcon from "../icons/availableIcon";
import OfflineIcon from "../icons/offlineIcon";
import BusyIcon from "../icons/busyIcon";
import TotalIcon from "../icons/totalIcon";
import { Card } from "../atoms/card";
import NumberChip from "../atoms/numberChip";
import Pusher from "pusher-js";
import { fetchChargerList } from "../../redux/apis/chargers";
import ChargerDetailsComponent from "../../components/chargerDetails/chargerDetailsComponent";
import ChargerDetailsComponentCpo from "../../components/chargerDetails/chargerDetailsComponentCpo";
import ChargerLocationMapComponent from "../../components/googleMap/ChargerLocationMapComponent";
import ModalComponent from "../atoms/ModalComponent";
import { handleCamelCase } from "../../utils/helpers";
import moment from "moment";
import { useTheme } from '@mui/material/styles';

function StatusCard() {
  const theme = useTheme();
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || JSON.parse(sessionStorage.getItem("userDetails"));
  const category = userDetails.category;
  const [availableChargers, setAvailableChargers] = useState([]);
  const [busyChargers, setBusyChargers] = useState([]);
  const [offlineChargers, setOfflineChargers] = useState([]);
  const [totalChargers, setTotalChargers] = useState([]);
  const [openChargersModal, setOpenChargersModal] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState([]);
  const [modalPage, setModalPage] = useState(0);
  const [modalRowsPerPage, setModalRowsPerPage] = useState(10);
  const [selectedChargerforMap, setSelectedChargerforMap] = useState(null);
  const [selectedChargerDetails, setSelectedChargerDetails] = useState(null);

  const fetchChargersBasedOnStatus = async () => {
    const [available, busy, offline, total] = await Promise.all([
      fetchChargerList({ limit: 500, filter: { status: "available" } }),
      fetchChargerList({ limit: 500, filter: { status: "busy" } }),
      fetchChargerList({ limit: 500, filter: { status: "offline" } }),
      fetchChargerList({ limit: 500 }),
    ]);

    setAvailableChargers(available.list || []);
    setBusyChargers(busy.list || []);
    setOfflineChargers(offline.list || []);
    setTotalChargers(total.list || []);
  };

  const [selectedChargersDetails, setSelectedChargersDetails] = useState({});
  const [selectedCharger, setSelectedCharger] = useState(null); // State to store selected charger for view modal


  // Function to open view modal and fetch charger data
  const handleViewCharger = (charger) => {
    setSelectedCharger(charger); // Set the selected charger for view modal
    setSelectedChargersDetails({
      "charger": charger,
      "open": true
    })
  };
  // const [pusherData, setPusherData] = useState(null);

  useEffect(() => {
    fetchChargersBasedOnStatus();
    // Initialize Pusher
    // const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
    //   cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    // });
    // const channel = pusher.subscribe('pusher-node-app');
    // channel.bind('charging-status-update', (data) => {
    //   setPusherData(data);
    // });

    // return () => {
    //   channel.unbind_all();
    //   channel.unsubscribe();
    // };
  }, []);


  const handleStatusClick = (status) => {
    switch (status) {
      case "available":
        setSelectedChargers(availableChargers);
        break;
      case "busy":
        setSelectedChargers(busyChargers);
        break;
      case "offline":
        setSelectedChargers(offlineChargers);
        break;
      default:
        setSelectedChargers(totalChargers);
    }
    setOpenChargersModal(true);
  };

  const handleModalChangePage = (event, newPage) => {
    setModalPage(newPage);
  };

  const handleModalChangeRowsPerPage = (event) => {
    setModalRowsPerPage(parseInt(event.target.value, 10));
    setModalPage(0);
  };

  const handleCloseChargerMapModal = () => {
    setSelectedChargerforMap(null);
  };


  const handleCloseChargersModal = () => {
    setOpenChargersModal(false);
  };

  const handleOpenChargerMapModal = (charger) => {
    setSelectedChargerforMap(charger);
  };


  return (
    <>
        <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"} marginBottom={"0.75rem"}>
          Charger Status
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              component={"div"}
              className={dashboardStyles.statuscard1}
              sx={{ cursor: "pointer" }}
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              px={"12px"}
              onClick={() => handleStatusClick("available")}
            >
              <Typography component={"span"} color={"#20C85D"} sx={{ background: "#20C85D", height: "50px", width: "50px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px" }}>
                <AvailableIcon />
              </Typography>
              <Typography component={"span"} color={"#20C85D"} fontWeight={"600"} fontSize={"1rem"}>Available</Typography>
              <Typography marginLeft={"auto"}>
                <NumberChip number={availableChargers.length} textColor={"#20C85D"} />
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              component={"div"}
              sx={{ cursor: "pointer" }}
              className={dashboardStyles.statuscard2}
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              px={"12px"}
              onClick={() => handleStatusClick("busy")}
            >
              <Typography component={"span"} color={theme.palette.tabActive} sx={{ background: theme.palette.tabActive, height: "50px", width: "50px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px" }}>
                <BusyIcon />
              </Typography>
              <Typography component={"span"} color={theme.palette.tabActive} fontWeight={"600"} fontSize={"1rem"}>Busy</Typography>
              <Typography marginLeft={"auto"}>
                <NumberChip number={busyChargers.length} textColor={theme.palette.tabActive} />
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              component={"div"}
              className={dashboardStyles.statuscard3}
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              px={"12px"}
              sx={{ cursor: "pointer" }}
              onClick={() => handleStatusClick("offline")}
            >
              <Typography component={"span"} color={"#F9A808"} sx={{ background: "#F9A808", height: "50px", width: "50px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px" }}>
                <OfflineIcon />
              </Typography>
              <Typography component={"span"} color={"#F9A808"} fontWeight={"600"} fontSize={"1rem"}>Offline</Typography>
              <Typography marginLeft={"auto"}>
                <NumberChip number={offlineChargers.length} textColor={"#F9A808"} />
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              component={"div"}
              className={dashboardStyles.statuscard4}
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              px={"12px"}
              onClick={() => handleStatusClick("total")}
            >
              <Typography component={"span"} color={"#20C85D"} sx={{ background: "#FE5657", height: "50px", width: "50px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px" }}>
                <TotalIcon />
              </Typography>
              <Typography component={"span"} color={"red"} fontWeight={"600"} fontSize={"1rem"}>Total</Typography>
              <Typography marginLeft={"auto"}>
                <NumberChip number={totalChargers.length} textColor={"red"} />
              </Typography>
            </Typography>
          </Grid>
        </Grid>

      {/* Modal for charger list */}

      {selectedChargerforMap && (
        <ChargerLocationMapComponent
          open={Boolean(selectedChargerforMap)}
          handleClose={handleCloseChargerMapModal}
          charger={selectedChargerforMap}
        />
      )}

      {(category == "CPO Admin") ? (
        <>
          <ModalComponent
            open={openChargersModal}
            handleClose={handleCloseChargersModal}
            headerText={"Chargers"}
            showSubmitButton={false}
          >
            <TableContainer
             component={Paper} sx={{maxHeight: "50vh"}}
            >
              <Table stickyHeader>
                <TableHead
                >
                  <TableRow>
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">ChargeBox ID</TableCell>
                    <TableCell align="center">EVSE Station</TableCell>
                    <TableCell align="center">Reg. date</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Charging Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                >

                  {selectedChargers.slice(modalPage * modalRowsPerPage, modalPage * modalRowsPerPage + modalRowsPerPage).map((charger, index) => (
                    <TableRow key={charger._id}>
                      <TableCell
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => handleViewCharger(charger)}
                        align="center"
                      >
                        {charger?.serialNumber}
                      </TableCell>
                      <TableCell align="center">{charger?.chargeBoxId}</TableCell>
                      <TableCell align="center">{charger?.evseStationName}</TableCell>
                      <TableCell align="center">
                        {moment(charger?.registeredAt).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell align="center">{handleCamelCase(charger?.status)}</TableCell>
                      <TableCell align="center">{charger?.chargingStatus}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={selectedChargers.length}
              rowsPerPage={modalRowsPerPage}
              page={modalPage}
              onPageChange={handleModalChangePage}
              onRowsPerPageChange={handleModalChangeRowsPerPage}
            />
          </ModalComponent>

          <ChargerDetailsComponent
            selectedCharger={selectedChargersDetails.charger}
            open={selectedChargersDetails.open}
            handleClose={() => setSelectedChargersDetails({ charger: null, open: false })}
          />
          <ChargerDetailsComponentCpo
            selectedCharger={selectedChargersDetails.charger}
            open={selectedChargersDetails.open}
            handleClose={() => setSelectedChargersDetails({ charger: null, open: false })}
          />
        </>

      ) : (
        <>
          <ModalComponent
            open={openChargersModal}
            handleClose={handleCloseChargersModal}
            headerText={"Chargers"}
            showSubmitButton={false}
          >
            <TableContainer component={Paper} sx={{maxHeight: "50vh"}}
            >
              <Table stickyHeader>
                <TableHead
                >
                  <TableRow>
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">ChargeBox ID</TableCell>
                    <TableCell align="center">EVSE Station</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">Location</TableCell>
                    <TableCell align="center">Reg. date</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Charging Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                >

                  {selectedChargers.slice(modalPage * modalRowsPerPage, modalPage * modalRowsPerPage + modalRowsPerPage).map((charger, index) => (
                    <TableRow key={charger._id}>
                      <TableCell
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => handleViewCharger(charger)}
                        align="center"
                      >
                        {charger?.serialNumber}
                      </TableCell>
                      <TableCell align="center">{charger?.chargeBoxId}</TableCell>
                      <TableCell align="center">{charger?.evseStationName}</TableCell>
                      <TableCell align="center">{charger?.evseStationAddress}</TableCell>
                      <TableCell align="center">
                        {charger.lat && charger.lng ? (
                          <a href="javascript:void(0)" onClick={() => handleOpenChargerMapModal(charger)} style={{ color: "#007bff", textAlign: "center" }}>
                            View On Map
                          </a>
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {moment(charger?.registeredAt).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell align="center">{handleCamelCase(charger?.status)}</TableCell>
                      <TableCell align="center">{charger?.chargingStatus}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={selectedChargers.length}
              rowsPerPage={modalRowsPerPage}
              page={modalPage}
              onPageChange={handleModalChangePage}
              onRowsPerPageChange={handleModalChangeRowsPerPage}
            />
          </ModalComponent>

          <ChargerDetailsComponent
            selectedCharger={selectedChargersDetails.charger}
            open={selectedChargersDetails.open}
            handleClose={() => setSelectedChargersDetails({ charger: null, open: false })}
          />
        </>
      )}
    </>
  );
}

export default StatusCard;
