import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import SystemStatus from "../../components/MonitorPageComponents/systemStatus";
import KernelLog from "../../components/MonitorPageComponents/kernelLog";
import FaqSection from "../../components/supportComponents/faqSection";
import Customerquery from "../../components/supportComponents/customerquery";
import Tickets from "../../components/supportComponents/tickets";
import {getSupportDetails} from "../../redux/apis/support";
import { message } from "antd";

function Support() {
  const [activeSettingTab, setSelectedActiveSettingTab] = useState("faq");
  const [faqs, setFaqs] = useState([]);
  const fetchSupportFaq = async () => {
    try {
      const response = await getSupportDetails();
      setFaqs(response.list);
    } catch (error) {
      console.error("Error fetching support details:", error);
      message.error("Failed to fetch support details faqs");
    }
  }
  useEffect(() => {
    fetchSupportFaq();
  }, []);


  return (
    <MainLayout setActiveTab={setSelectedActiveSettingTab}>
      {activeSettingTab === "faq" && <FaqSection faqs={faqs} />}
      {activeSettingTab === "customer_query" && <Customerquery />}
      {activeSettingTab === "tickets" && <Tickets />}
    </MainLayout>
  );
}

export default Support;
