import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TextField,
    Grid,
    Select,
    MenuItem,
    IconButton,
    Switch,
    styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModalComponent from '../../../components/atoms/ModalComponent';

const PartnersTab = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [partnerTypes, setPartnerTypes] = useState([
        'EVSE Investor',
        'Site Host',
    ]);
    const [newPartnerType, setNewPartnerType] = useState('');
    const [showAddTypeField, setShowAddTypeField] = useState(false);
    const [permissions, setPermissions] = useState({
        chargers: true,
        evseStations: false,
        revenueManagement: true,
    });

    const businesses = ['TruCharge', 'Mastercard', 'Walmart'];
    const partnerNames = ['John Doe', 'Jane Smith', 'Steve Smith'];
    const Partners = Array.from({ length: 3 }, (_, i) => ({
        name: businesses[i],
        country: i % 2 === 0 ? 'Malaysia' : 'Singapore',
        partner: partnerNames[i],
        partnerType: i % 2 === 0 ? 'EVSE Investor' : 'Site Host',
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddPartnerType = () => {
        if (newPartnerType.trim() && !partnerTypes.includes(newPartnerType)) {
            setPartnerTypes([...partnerTypes, newPartnerType]);
            setNewPartnerType('');
            setShowAddTypeField(false);
        }
    };

    const handlePermissionToggle = (permission) => {
        setPermissions((prev) => ({
            ...prev,
            [permission]: !prev[permission],
        }));
    };


    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent:"flex-end"}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsModalOpen(true)}
                    sx={{ marginBottom: 2, color: '#fff' }}
                >
                    + Add Partner
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: '45vh' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Business Name</TableCell>
                            <TableCell align="center">Country</TableCell>
                            <TableCell align="center">Partner Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Partners.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        ).map((Partner, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{Partner.name}</TableCell>
                                <TableCell align="center">{Partner.country}</TableCell>
                                <TableCell align="center">{Partner.partner}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={Partners.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ModalComponent
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                headerText="Add Partner"
                showSubmitButton={true}
                submitButtonLabel="Add"
            >
                <Box component="form" sx={{ p: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Partner Details
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Partner Name"
                                fullWidth
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Business Name"
                                fullWidth
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email ID"
                                fullWidth
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Country"
                                fullWidth
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Taxation ID"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                Partner Type
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Select
                                    fullWidth
                                    value=""
                                    displayEmpty
                                    sx={{ flexGrow: 1, marginRight: 1 }}
                                    onChange={(e) => {
                                        if (e.target.value === 'addNew') {
                                            setShowAddTypeField(true);
                                        }
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select Partner Type
                                    </MenuItem>
                                    {partnerTypes.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="addNew">
                                        <AddIcon fontSize="small" /> Add New Type
                                    </MenuItem>
                                </Select>
                            </Box>
                            {showAddTypeField && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <TextField
                                        label="New Partner Type"
                                        variant="outlined"
                                        fullWidth
                                        value={newPartnerType}
                                        onChange={(e) => setNewPartnerType(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddPartnerType}
                                        sx={{ ml: 2 }}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                Permissions
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2, flexWrap: "wrap" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography>Chargers (View)</Typography>
                                    <Switch
                                        checked={permissions.chargers}
                                        onChange={() => handlePermissionToggle('chargers')}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography>EVSE Stations (View)</Typography>
                                    <Switch
                                        checked={permissions.evseStations}
                                        onChange={() => handlePermissionToggle('evseStations')}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography>Multi-party Revenue Mgt (View)</Typography>
                                    <Switch
                                        checked={permissions.revenueManagement}
                                        onChange={() => handlePermissionToggle('revenueManagement')}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </ModalComponent>
        </Box>
    );
};

export default PartnersTab;
