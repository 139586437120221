import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Grid,
    MenuItem,
    Select,
    IconButton,
    Divider,
    FormControl,
    InputLabel,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ModalComponent from '../../../components/atoms/ModalComponent';

const stakeholdersList = ['TruCharge', 'Mastercard', 'Walmart'];

const generateDummyData = () => {
    return Array.from({ length: 3 }, (_, i) => ({
        rule: `Rule ${i + 1}`,
        stations: i == 2 ? "0" : [`Station ${i + 1}`, `Station ${i + 2}`],
        chargers: i == 2 ? "0" : Math.floor(Math.random() * 20) + 1,
        country: i % 2 === 0 ? 'Malaysia' : 'Singapore',
        validity: { from: `2025-01-${i + 10}`, to: `2025-02-${i + 15}` },
        stakeholders: [
            { name: 'TruCharge', percentage: Math.floor(Math.random() * 60) + 20 },
            { name: 'Mastercard', percentage: Math.floor(Math.random() * 20) + 10 },
            { name: 'Walmart', percentage: Math.floor(Math.random() * 20) + 10 },
        ],
    }));
};

const AllocationRulesTab = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allocationRules, setAllocationRules] = useState(generateDummyData());
    const [newRule, setNewRule] = useState({
        ruleName: '',
        validity: { from: '', to: '' },
        stakeholders: [{ name: 'TruCharge', percentage: "" }],
    });

    const totalPercentage = newRule.stakeholders.reduce(
        (acc, stakeholder) => acc + parseInt(stakeholder.percentage || 0, 10),
        0
    );

    const handleStakeholderChange = (index, field, value) => {
        const updatedStakeholders = [...newRule.stakeholders];
        updatedStakeholders[index][field] = value;
        setNewRule({ ...newRule, stakeholders: updatedStakeholders });
    };

    const addStakeholder = () => {
        setNewRule({
            ...newRule,
            stakeholders: [...newRule.stakeholders, { name: '', percentage: '' }],
        });
    };

    const removeStakeholder = (index) => {
        const updatedStakeholders = newRule.stakeholders.filter((_, idx) => idx !== index);
        setNewRule({ ...newRule, stakeholders: updatedStakeholders });
    };

    const handleFormSubmit = () => {
        const newAllocationRule = {
            rule: newRule.ruleName,
            stations: [],
            chargers: 0,
            validity: newRule.validity,
            stakeholders: newRule.stakeholders,
        };

        setAllocationRules([...allocationRules, newAllocationRule]);
        setIsModalOpen(false);
        setNewRule({
            ruleName: '',
            validity: { from: '', to: '' },
            stakeholders: [{ name: 'TruCharge', percentage: 100 }],
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsModalOpen(true)}
                    sx={{ color: '#fff' }}
                >
                    + Add Allocation Rule
                </Button>
            </Box>

            <Box sx={{ padding: 2, overflow: 'auto', maxHeight: '60vh' }}>
                {allocationRules.map((rule, index) => (
                    <Box
                        key={index}
                        sx={{
                            marginBottom: 3,
                            padding: 3,
                            border: '1px solid #ccc',
                            borderRadius: '12px',
                            backgroundColor: '#fdfdfd',
                        }}
                    >
                        <Typography variant="h6" fontWeight="600">
                            {rule.rule}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Partner</TableCell>
                                    <TableCell align="left">Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rule.stakeholders.map((stakeholder, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell align="left">{stakeholder.name}</TableCell>
                                        <TableCell align="left">{stakeholder.percentage}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                ))}
            </Box>

            <ModalComponent
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                headerText="Add Allocation Rule"
                showSubmitButton={true}
                submitButtonLabel="Save"
                onSubmit={handleFormSubmit}
            >
                <Box component="form" sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Rule Name"
                                fullWidth
                                value={newRule.ruleName}
                                onChange={(e) => setNewRule({ ...newRule, ruleName: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Validity From"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={newRule.validity.from}
                                onChange={(e) =>
                                    setNewRule({ ...newRule, validity: { ...newRule.validity, from: e.target.value } })
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Validity To"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={newRule.validity.to}
                                onChange={(e) =>
                                    setNewRule({ ...newRule, validity: { ...newRule.validity, to: e.target.value } })
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                Partners:
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Partner</TableCell>
                                        <TableCell align="right">Percentage</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newRule.stakeholders.map((stakeholder, idx) => (
                                        <TableRow key={idx}>
                                            <TableCell>
                                                <FormControl fullWidth>
                                                    <InputLabel>Select Stakeholder</InputLabel>
                                                    <Select
                                                        value={stakeholder.name}
                                                        onChange={(e) => handleStakeholderChange(idx, 'name', e.target.value)}
                                                    >
                                                        {stakeholdersList.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    type="number"
                                                    label="Percentage"
                                                    value={stakeholder.percentage}
                                                    onChange={(e) =>
                                                        handleStakeholderChange(idx, 'percentage', e.target.value)
                                                    }
                                                    inputProps={{ min: 0, max: 100 }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                {stakeholder.name === "TruCharge" ? "Default" : <IconButton color="error" onClick={() => removeStakeholder(idx)}>
                                                    <DeleteIcon />
                                                </IconButton>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Typography variant="subtitle2" sx={{ mt: 1 }}>
                                Total Allocation: {totalPercentage}% (Maximum: 100%)
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ mt: 2 }}
                                onClick={addStakeholder}
                                disabled={totalPercentage >= 100}
                            >
                                + Add Partner
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </ModalComponent>
        </>
    );
};

export default AllocationRulesTab;
