// src/theme.js
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

export default function DynamicThemeProvider({ children }) {

  const selectedTheme = useSelector((state) => state.theme);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#AAAAAA", // Black for primary elements
      },
      secondary: {
        main: "#FFFFFF", // White for secondary elements
      },
      background: {
        default: "#FFFFFF",
        paper: "#F8F8F8",
      },
      text: {
        primary: "#343434",
        secondary: "#444444",
      },
      tertiary: {
        main: selectedTheme.tertiaryBlue
      },
      iconColor: {
        main: selectedTheme.hoverBlue, // Custom color for icons
      },
      tabActive: selectedTheme.activeTabBlue, // Dynamic active tab blue color
    },
    typography: {
      fontFamily: ["poppins"],
      fontSize: 12,
      h1: {
        fontSize: "1rem",
        fontWeight: 600,
      },
      h2: {
        fontSize: "0.7rem",
        fontWeight: 600,
      },
      span: {
        fontSize: "12px",
      },
      p: {
        fontSize: "12px",
      },
      div: {
        fontSize: "12px",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#343434", // Default label color
            '&.Mui-focused': {
              color: "#343434", // Active/focused label color
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: selectedTheme.primaryBlue, // Hover state
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: selectedTheme.primaryBlue, // Active/focused state
            },
          },
          notchedOutline: {
            borderColor: selectedTheme.tertiaryBlue, // Default border color
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: selectedTheme.primaryBlue, // Active state color (checked)
            },
            '&.Mui-disabled': {
              color: selectedTheme.tertiaryBlue, // Disabled state color
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '&.Mui-checked': {
              color: selectedTheme.primaryBlue, // Active state color (checked)
            },
            '&.Mui-disabled': {
              color: selectedTheme.tertiaryBlue, // Disabled state color
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "*": {
            scrollbarWidth: "thin", // For Firefox
            scrollbarColor: selectedTheme.tertiaryBlue + " transparent", // Thumb and track colors for Firefox
          },
          "*::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar for Webkit browsers
            height: "8px", // Height for horizontal scrollbar
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: selectedTheme.tertiaryBlue, // Thumb color
            borderRadius: "8px", // Rounded edges for thumb
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: "transparent", // Track color
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: selectedTheme.primaryBlue, // Dynamic progress indicator color
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: selectedTheme.activeTabBlue, // Updated to dynamic active tab color
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: selectedTheme.activeTabBlue, // Dynamic active tab text color
              backgroundColor: selectedTheme.tertiaryBlue, // Updated to dynamic lighter blue
              borderRadius: "12px",
              margin: "4px",
              border: `1px solid ${selectedTheme.activeTabBlue}`, // Dynamic border color
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "12px",
          },
          contained: {
            color: "#FFFFFF",
            backgroundColor: selectedTheme.primaryBlue, // Dynamic button background color
            "&:hover": {
              backgroundColor: selectedTheme.hoverBlue, // Dynamic button hover color
            },
          },
          outlined: {
            color: selectedTheme.primaryBlue,
            borderColor: selectedTheme.primaryBlue,
            "&:hover": {
              backgroundColor: `rgba(${hexToRgb(selectedTheme.primaryBlue)}, 0.08)`, // Slight hover effect with dynamic color
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            display: 'flex',
          },
          switchBase: {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(14px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: selectedTheme.primaryBlue, // Active color
                opacity: 1,
              },
            },
          },
          thumb: {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 22,
            height: 22,
            borderRadius: 11,
          },
          track: {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA', // Inactive color
            opacity: 1,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            transform: "scale(0.8)", // Scaling down the card size
            transformOrigin: "top left", // Ensuring scaling starts from the top left corner
            backgroundColor: "#F8F8F8", // Off-white card background
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: "1rem",
            fontWeight: 600,
            color: "#343434", // Black typography
          },
          h2: {
            fontSize: "0.7rem",
            fontWeight: 500,
            color: "#343434", // Black typography
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            padding: "10px 5px",
            backgroundColor: selectedTheme.tertiaryBlue, // Black background for header
            color: "#343434", // White text for header
          },
          body: {
            padding: "10px 5px",
            backgroundColor: "#FFFFFF", // White background for body
            color: "#343434", // Black text for body
            borderBottom: `1px solid  ${selectedTheme.tertiaryBlue}`
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;

  // Helper function to convert hex to RGB
  function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  }
}  