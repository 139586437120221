import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Select, FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Add, Assignment } from "@mui/icons-material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Grid, message } from "antd";
import moment from "moment";
import Pusher from 'pusher-js';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Paper
} from "@mui/material"; // Import Dialog for confirmation popup
import ButtonComponent from "../../components/atoms/button/button";
import ModalComponent from "../../components/atoms/ModalComponent";
import ChargerLocationMapComponent from "../../components/googleMap/ChargerLocationMapComponent";
import {
  fetchChargerList,
  assignChargerAPI,
  fetchChargerLocationEvseAssignedAPI
} from "../../redux/apis/chargers";
import { getData } from 'country-list';
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import ChargerDetailsComponent from "../../components/chargerDetails/chargerDetailsComponentCpo";
import { useSelector } from "react-redux";

function Chargers() {

  const [chargers, setChargers] = useState([]);
  const [allChargers, setAllChargers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalChargers, setTotalChargers] = useState(0);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false); // State for edit modal
  const [selectedChargerforMap, setSelectedChargerforMap] = useState(null);
  const [newChargerData, setNewChargerData] = useState({
    serialNumber: "",
    evseStationId: "",
  });
  const [selectedChargerId, setSelectedChargerId] = useState(""); // State to store selected charger ID for edit
  const [loading, setLoading] = useState(false); // State variable to track loading
  const selectedFilter = useSelector(state => state.filter.selectedFilter);
  const [evseStations, setEvseStations] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedSiteHostAdminId, setSelectedSiteHostAdminId] = useState("");
  const [statusFilter, setStatusFilter] = useState('');
  const [chargingStatusFilter, setChargingStatusFilter] = useState('');
  const [regDateSort, setRegDateSort] = useState("desc");

  const cpoDetails = JSON.parse(localStorage.getItem("cpoDetails")) || JSON.parse(sessionStorage.getItem("cpoDetails")) || {};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchChargers = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await fetchChargerList({
        search: searchQuery,
        filter: (selectedFilter ? `{ "EvseStationId": "${selectedFilter.value}" }` : "")
          || (statusFilter ? `{ "status": "${statusFilter}" }` : "")
          || (chargingStatusFilter ? `{ "chargingStatus": "${chargingStatusFilter}" }` : ""),
        page: page + 1,
        sort: `registeredAt:${regDateSort}`,
        limit: rowsPerPage,
      });
      setChargers(response.list || []);
      setTotalChargers(response.totalCount || 0);
    } catch (error) {
      console.error("Error fetching chargers:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const fetchAllChargers = async () => {
    try {
      const response = await fetchChargerList({ limit: 10000 }); // Adjust limit as needed
      setAllChargers(response.list || []); // Save all chargers for autocomplete
    } catch (error) {
      console.error("Error fetching all chargers:", error);
    }
  };

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe('pusher-node-app');

    // Bind to the event
    channel.bind('charging-status-update', (data) => {
      console.log(data)
      updateChargingStatus(data.locationData.all);
    });

    // Initial fetch
    fetchChargers();

  }, []);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe('pusher-node-app');

    const chargerUpdatedChannel = pusher.subscribe(cpoDetails?.id);

    // Bind to the 'charger-registered' event
    channel.bind('charger-registered', (data) => {

      // Fetch chargers data again when the event is triggered
      fetchChargers();
    });

    chargerUpdatedChannel.bind('charger-updated', (data) => {
      // Fetch chargers data again when the event is triggered
      fetchChargers();
    })

    channel.bind('charger-assigned', (data) => {
      // Fetch chargers data again when the event is triggered
      fetchChargers();
    });

    channel.bind('charger-activated', (data) => {
      // Fetch chargers data again when the event is triggered
      fetchChargers();
    });
    // Clean up on unmount
    return () => {
      channel.unbind('charger-assigned');
      channel.unbind('charger-activated');
      channel.unbind('charger-registered');
      chargerUpdatedChannel.unbind('charger-updated');
      pusher.unsubscribe(cpoDetails.cpoId);
      pusher.unsubscribe('pusher-node-app');
      
    };
  }, []);



  const updateChargingStatus = (updatedChargers) => {
    setChargers((prevChargers) =>
      prevChargers.map((charger) => {
        const updatedCharger = updatedChargers.find((uc) => uc.chargeBoxId === charger.chargeBoxId);

        // Update only the chargingStatus field
        if (updatedCharger) {
          return {
            ...charger,
            chargingStatus: updatedCharger.chargingStatus,
            status: updatedCharger.status,
          };
        }

        return charger;
      })
    );
  };

  const updateChargerStatus = (updatedChargers) => {
    setChargers((prevChargers) =>
      prevChargers.map((charger) => {
        const updatedCharger = updatedChargers.find((uc) => uc.chargeBoxId === charger.chargeBoxId);

        // Update only the chargingStatus field
        if (updatedCharger) {
          return {
            ...charger,
            status: updatedCharger.status,
          };
        }

        return charger;
      })
    );
  }


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenAssignModal = (chargerId) => {
    setSelectedChargerId(chargerId); // Set the selected charger ID for edit
    const chargerToEdit = chargers.find((charger) => charger.id === chargerId);

    // Set the existing charger data to prefill the modal fields
    setNewChargerData({
      evseStationId: chargerToEdit.evseStationId,
      serialNumber: chargerToEdit.serialNumber,
    });
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    setNewChargerData({
      serialNumber: "",
      evseStationId: "",
    })
    fetchChargers();
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };


  const handleOpenEditModal = (chargerId) => {
    setSelectedChargerId(chargerId); // Set the selected charger ID for edit
    // Find the charger object with the corresponding ID
    const chargerToEdit = chargers.find((charger) => charger.id === chargerId);

    // Set the existing charger data to prefill the modal fields
    setNewChargerData({
      evseStationId: chargerToEdit.evseStationId,
      serialNumber: chargerToEdit.serialNumber,
    });
    setOpenEditModal(true);
  };

  const handleUpdateCharger = async () => {
    try {
      setLoading(true);
      // Call your API to update the charger data with the newChargerData
      // Replace this with your actual API call
      await assignChargerAPI(selectedChargerId, {
        "evseStationId": newChargerData.evseStationId
      });
      message.success("EVSE Station Assigned Successfully");
      fetchChargers();
    } catch (error) {
      console.error("Error: Failed to assign EVSE Station", error);
      message.error(error?.response?.data?.message || "Something Went Wrong While Assigning Evse Station");
    }
    finally {
      setLoading(false);
      handleCloseEditModal();
      handleCloseAssignModal();
    }
  };



  const handleNewChargerInputChange = (event) => {
    const { name, value } = event.target;
    setNewChargerData({ ...newChargerData, [name]: value });

  };


  useEffect(() => {
    fetchChargers();
  }, [selectedFilter, statusFilter, chargingStatusFilter, regDateSort, page, rowsPerPage, searchQuery]);
  const handleClearFilter = (setter) => () => {
    setter('');
  };

  const formatSerialNumber = (serialNumber) => {
    const formattedSerialNumber = `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
    return formattedSerialNumber;
  }

  const fetchEvseStations = async () => {
    try {
      const response = await fetchEvseStationsAPI({
        limit: 500,
      }); // Replace fetchEvseStationsAPI with your actual API function
      setEvseStations(response.list || []);
    } catch (error) {
      console.error("Error fetching EVSE stations:", error);
    }
  };

  const [selectedChargersDetails, setSelectedChargersDetails] = useState({});

  // Function to open view modal and fetch charger data
  const handleViewCharger = (charger) => {
    setSelectedChargersDetails({
      "charger": charger,
      "open": true
    })
  };

  const handleCloseMapModal = () => {
    setSelectedChargerforMap(null);
  };
  const handleOpenMapModal = (site) => {
    setSelectedChargerforMap(site);
  };

  useEffect(() => {
    fetchAllChargers();
  }, []);

  useEffect(() => {
    if (selectedSiteHostAdminId) {
      fetchEvseStations(selectedSiteHostAdminId);
    }
  }, [selectedSiteHostAdminId]);


  useEffect(() => {
    fetchEvseStations();
  }, [selectedSiteId]);

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleToggleSort = () => {
    setRegDateSort((prev) => (prev === "asc" ? "desc" : "asc"));
    // You can trigger your data sorting logic here
  };

  const fetchChargerLocationEvseAssigned = async (chargeBoxId) => {
    try {
      await fetchChargerLocationEvseAssignedAPI(chargeBoxId);
      message.success("Charger Location Fetched Successfully");
      fetchChargers();
    } catch (error) {
      console.error("Error fetching charger location and evse assigned:", error);
    }
  };


  return (
    <>
      <MainLayout>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem"}
        >
          <Typography>
            <TextField
              id="s"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
        </Typography>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: '50vh' }}
            >
              <Table
                stickyHeader 
              >
                <TableHead
                >
                  <TableRow
                  >
                    <TableCell align="center">Sr No.</TableCell>
                    <TableCell align="center">ChargeBox ID</TableCell>
                    <TableCell align="center">EVSE Station</TableCell>
                    <TableCell align="center">Location</TableCell>
                    <TableCell align="center">Last Active</TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Button
                          onClick={handleToggleSort}
                          sx={{

                            color: "#333", // White text for active, dark grey for default
                            borderRadius: "8px", // Softer radius
                            fontSize: "13px",
                            fontWeight: "500",
                            padding: "4px 8px", // Adjust padding for smaller height
                            minHeight: "32px", // Reduce the minimum height
                            ".MuiSelect-select": {
                              padding: "4px 8px", // Adjust padding inside the select
                            }
                          }}
                        >
                          Reg. Date
                          {regDateSort === "asc" ? (
                            <ArrowUpwardIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                          ) : (
                            <ArrowDownwardIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                          )}
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell align="center" >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Select
                          value={statusFilter}
                          onChange={handleFilterChange(setStatusFilter)}
                          sx={{
                            color: "#333", // White text for active, dark grey for default
                            borderRadius: "8px", // Softer radius
                            fontSize: "13px",
                            fontWeight: "500",
                            padding: "4px 8px", // Adjust padding for smaller height
                            minHeight: "32px", // Reduce the minimum height
                            ".MuiSelect-select": {
                              padding: "4px 8px", // Adjust padding inside the select
                            },
                            "fieldset": {
                              border: "none"
                            }
                          }}
                          displayEmpty
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                bgcolor: "#f5f5f5", // Background color of dropdown
                                color: "#000", // Text color of dropdown items
                                maxHeight: "200px", // Limit height for the dropdown
                                borderRadius: "8px", // Softer radius for dropdown
                              },
                            },
                          }}
                        >
                          <MenuItem value="">Status</MenuItem>
                          <MenuItem value="registered">Registered</MenuItem>
                          <MenuItem value="activated">Activated</MenuItem>
                          <MenuItem value="available">Available</MenuItem>
                          <MenuItem value="busy">Busy</MenuItem>
                          <MenuItem value="offline">Offline</MenuItem>
                          <MenuItem value="disabled">Disabled</MenuItem>
                        </Select>
                        {statusFilter && (
                          <IconButton
                            size="small"
                            onClick={handleClearFilter(setStatusFilter)}
                            sx={{
                              marginLeft: "8px",
                              border: "1px solid #ff3232",
                              color: "#616161",
                              borderRadius: "50%", // Round button
                              width: "16px",
                              height: "16px",
                              "&:hover": {
                                borderColor: "#ff5b5b"
                              }
                            }}
                          >
                            <CloseIcon fontSize="small" sx={{ height: "10px", color: "#ff3232", "&:hover": { color: "#ff5b5b" } }} />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div style={{ display: "flex", alignItems: "center", border: "none", justifyContent: "center" }}>
                        <Select
                          value={chargingStatusFilter}
                          onChange={handleFilterChange(setChargingStatusFilter)}
                          sx={{
                            color: "#333", // White text for active, dark grey for default
                            borderRadius: "8px", // Softer radius
                            fontSize: "13px",
                            fontWeight: "500",
                            padding: "4px 8px", // Adjust padding for smaller height
                            minHeight: "32px", // Reduce the minimum height
                            ".MuiSelect-select": {
                              padding: "4px 8px", // Adjust padding inside the select
                            },
                            "fieldset": {
                              border: "none"
                            }
                          }}
                          displayEmpty
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                bgcolor: "#f5f5f5", // Background color of dropdown
                                color: "#000", // Text color of dropdown items
                                maxHeight: "200px", // Limit height for the dropdown
                              },
                            },
                          }}
                        >
                          <MenuItem value="">Charging Status</MenuItem>
                          <MenuItem value="available">Available</MenuItem>
                          <MenuItem value="preparing">Preparing</MenuItem>
                          <MenuItem value="charging">Charging</MenuItem>
                          <MenuItem value="suspendedEVSE">Suspended EVSE</MenuItem>
                          <MenuItem value="suspendedEV">Suspended EV</MenuItem>
                          <MenuItem value="finishing">Finishing</MenuItem>
                          <MenuItem value="reserved">Reserved</MenuItem>
                          <MenuItem value="unavailable">Unavailable</MenuItem>
                          <MenuItem value="faulted">Faulted</MenuItem>
                        </Select>
                        {chargingStatusFilter && (
                          <IconButton
                            size="small"
                            onClick={handleClearFilter(setChargingStatusFilter)}
                            sx={{
                              marginLeft: "8px",
                              border: "1px solid #ff3232",
                              color: "#616161",
                              borderRadius: "50%", // Round button
                              width: "16px",
                              height: "16px",
                              "&:hover": {
                                borderColor: "#ff5b5b"
                              }
                            }}
                          >
                            <CloseIcon fontSize="small" sx={{ height: "10px", color: "#ff3232", "&:hover": { color: "#ff5b5b" } }} />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                >
                  {chargers.map((charger, index) => (
                    <TableRow key={charger.id}>
                      <TableCell
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => handleViewCharger(charger)}
                        align="center"
                      >
                        {formatSerialNumber(charger.serialNumber)}
                      </TableCell>
                      <TableCell align="center" >{charger?.chargeBoxId}</TableCell>
                      <TableCell align="center">
                        {charger?.evseStationName === null ? <Button onClick={() => handleOpenAssignModal(charger.id)} sx={{ textDecoration: "underline", color: "#007BFF" }}>Assign EVSE Station</Button> : charger?.evseStationName}
                      </TableCell>
                      <TableCell align="center">
                      {charger.lat && charger.lng ? (
                          <Button onClick={() => handleOpenMapModal(charger)} sx={{ color: "#007bff", textDecoration: "underline", textAlign: "center" }}>
                            View On Map
                          </Button>
                        ) : (
                          charger.evseStationName ? <Button onClick={() => fetchChargerLocationEvseAssigned(charger.chargeBoxId)} sx={{ color: "#007bff", textDecoration: "underline", textAlign: "center" }}>
                          Fetch Location
                        </Button> : "--"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {charger?.lastHeartbeat ? moment(charger.lastHeartbeat).format("DD MMM YYYY") : '--'}
                      </TableCell>
                      <TableCell align="center">
                        {charger?.registeredAt ? moment(charger.registeredAt).format("DD MMM YYYY") : '--'}
                      </TableCell>
                      <TableCell align="center">{charger?.status}</TableCell>
                      <TableCell align="center">{charger?.chargingStatus}</TableCell>
                      <TableCell align="center">
                        <Typography sx={{ display: "flex" }}>
                          <IconButton
                            onClick={() => handleOpenEditModal(charger?.id)} // Implement edit charger action
                          >
                            <EditIcon style={{ color: "#5882E6" }} />
                          </IconButton>

                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalChargers}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}

        {/* Edit Modal */}
        <ModalComponent
          open={openEditModal}
          handleClose={handleCloseEditModal}
          headerText={"Edit Charger"}
          handleSubmit={handleUpdateCharger} // Assuming this function is implemented for updating charger data
          submitButtonLabel={"Update Charger"}
        >
          <TextField
            id="serialNumber"
            name="serialNumber"
            label="Serial Number"
            variant="outlined"
            fullWidth
            disabled
            value={newChargerData.serialNumber}
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          />
          <TextField
            id="stationId"
            select
            name="evseStationId"
            label="EVSE Station"
            variant="outlined"
            fullWidth
            value={newChargerData.evseStationId}
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          >
            {evseStations.map((station) => (
              <MenuItem key={station.id} value={station.id}>
                {station.name}
              </MenuItem>
            ))}
          </TextField>

        </ModalComponent>

        <ModalComponent
          open={openAssignModal}
          handleClose={handleCloseAssignModal}
          headerText={"Edit Charger"}
          handleSubmit={handleUpdateCharger} // Assuming this function is implemented for updating charger data
          submitButtonLabel={"Assign Charger"}
        >
          <TextField
            id="serialNumber"
            name="serialNumber"
            label="Serial Number"
            variant="outlined"
            fullWidth
            disabled
            value={newChargerData.serialNumber}
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          />
          <TextField
            id="stationId"
            select
            name="evseStationId"
            label="EVSE Station"
            variant="outlined"
            fullWidth
            value={newChargerData.evseStationId}
            onChange={handleNewChargerInputChange}
            style={{ marginBottom: 10 }}
          >
            {evseStations.map((station) => (
              <MenuItem key={station.id} value={station.id}>
                {station.name}
              </MenuItem>
            ))}
          </TextField>

        </ModalComponent>

        <ChargerDetailsComponent
          selectedCharger={selectedChargersDetails.charger}
          open={selectedChargersDetails.open}
          handleClose={() => setSelectedChargersDetails({ charger: null, open: false })}
          fetchChargers={fetchChargers}
        />
        {selectedChargerforMap && (
          <ChargerLocationMapComponent
            open={Boolean(selectedChargerforMap)}
            handleClose={handleCloseMapModal}
            charger={selectedChargerforMap}
          />
        )}
      </MainLayout>
    </>
  );
};
export default Chargers;
