// MultiPartyRevenue.js
import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import StakeholdersTab from './multiPartyTabs/StakeholdersTab';
import AllocationRulesTab from './multiPartyTabs/AllocationRulesTab';
import MainLayout from '../../layout/mainLayout';

const MultiPartyRevenue = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <MainLayout>
            <Box sx={{ marginBottom: 3 }}>
                <Tabs value={activeTab} onChange={handleTabChange} indicatorColor='transparent' sx={{ backgroundColor: "#FFFFFF", borderRadius: "18px", border:"2px solid #dce5f0", padding: "5px" }}>
                    <Tab label="Partners" />
                    <Tab label="Allocation Rules" />
                </Tabs>

                <Box sx={{ marginTop: 2 }}>
                    {activeTab === 0 && <StakeholdersTab />}
                    {activeTab === 1 && <AllocationRulesTab />}
                </Box>
            </Box>
        </MainLayout>
    );
};

export default MultiPartyRevenue;