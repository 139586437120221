import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";

function SupportTab({ setActiveTab }) {
  const [value, setValue] = React.useState("faq");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Typography>
      <Box
        sx={{ background: "white", width: "100%" }}
        borderRadius={"14px"}
    
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="white"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="faq"
            label="FAQ's"
            onClick={() => setActiveTab("faq")}
            sx={{ textTransform: "capitalize" }}
          />
          <Tab
            value="customer_query"
            label="Customer Query"
            onClick={() => setActiveTab("customer_query")}
            sx={{ textTransform: "capitalize" }}
          />
          <Tab
            value="tickets"
            label="Tickets"
            onClick={() => setActiveTab("tickets")}
            sx={{ textTransform: "capitalize" }}
          />
        </Tabs>
      </Box>
    </Typography>
  );
}

export default SupportTab;
