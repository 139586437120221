import React from 'react'

const Finance = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.64087 1.09158C9.86473 0.969474 10.1353 0.969474 10.3591 1.09158L18.6091 5.59158C18.9101 5.75575 19.0615 6.10311 18.9768 6.43532C18.892 6.76752 18.5928 7 18.25 7H1.75001C1.40717 7 1.10798 6.76752 1.02326 6.43532C0.938553 6.10311 1.08989 5.75575 1.39087 5.59158L9.64087 1.09158ZM4.69125 5.5H15.3088L10 2.60432L4.69125 5.5ZM2.00001 8.75C2.00001 8.33579 2.3358 8 2.75001 8H17.25C17.6642 8 18 8.33579 18 8.75C18 9.16421 17.6642 9.5 17.25 9.5H2.75001C2.3358 9.5 2.00001 9.16421 2.00001 8.75ZM4.00001 11C4.41422 11 4.75001 11.3358 4.75001 11.75V15.25C4.75001 15.6642 4.41422 16 4.00001 16C3.5858 16 3.25001 15.6642 3.25001 15.25V11.75C3.25001 11.3358 3.5858 11 4.00001 11ZM8.00001 11C8.41422 11 8.75001 11.3358 8.75001 11.75V15.25C8.75001 15.6642 8.41422 16 8.00001 16C7.5858 16 7.25001 15.6642 7.25001 15.25V11.75C7.25001 11.3358 7.5858 11 8.00001 11ZM12 11C12.4142 11 12.75 11.3358 12.75 11.75V15.25C12.75 15.6642 12.4142 16 12 16C11.5858 16 11.25 15.6642 11.25 15.25V11.75C11.25 11.3358 11.5858 11 12 11ZM16 11C16.4142 11 16.75 11.3358 16.75 11.75V15.25C16.75 15.6642 16.4142 16 16 16C15.5858 16 15.25 15.6642 15.25 15.25V11.75C15.25 11.3358 15.5858 11 16 11ZM1.00001 18.25C1.00001 17.8358 1.3358 17.5 1.75001 17.5H18.25C18.6642 17.5 19 17.8358 19 18.25C19 18.6642 18.6642 19 18.25 19H1.75001C1.3358 19 1.00001 18.6642 1.00001 18.25Z" fill={props.active}/>
</svg>
  )
}

export default Finance
