import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  IconButton,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Add, Edit, Search, Delete } from "@mui/icons-material";
import ModalComponent from "../../components/atoms/ModalComponent";
import ButtonComponent from "../../components/atoms/button/button";
import { message } from "antd";
import {
  fetchEMSPUsers,
  deleteEMSPUser,
  updateEMSPUser,
  addEMSPUser,
  fetchRoles,
} from "../../redux/apis/emsp";
import PhoneComponent from "../../components/phoneNumber/phone"
import { useTheme } from '@mui/material/styles';
import MainLayout from "../../layout/mainLayout";

function UserManagementContainer() {
  const theme = useTheme();
  const storedDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const userDetails = storedDetails && JSON.parse(storedDetails);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    userRoleId: "",
  });
  const [errors, setErrors] = useState({}); // State to track form errors
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete dialog, ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);


  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchEMSPUsers({
        search: searchQuery
      });
      setUsers(response.list);
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error("Failed To Fetch Users.");
    } finally {
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await fetchRoles();
      setRoles(response.list);
    } catch (error) {
      console.error("Error fetching roles:", error);
      message.error("Failed To Fetch Roles.");
    }
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  useEffect(()=>{
    getUsers();
  },[searchQuery])

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openEditUserModal = (user) => {
    setSelectedUser(user);
    setUserData({
      name: user.firstName + " " + user.lastName,
      email: user.email,
      phone: user.phoneNumber,
      userRoleId: user.userRoleId,
    });
    setErrors({});
    setEditUserModalOpen(true);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!userData.name) newErrors.name = "Name is required.";
    if (!userData.email) newErrors.email = "Email is required.";

    if (!userData.userRoleId) newErrors.userRoleId = "Role is required.";

    if (!userData.phone) newErrors.phone = "Phone number is required.";
    else if (isNaN(userData.phone)) {
      newErrors.phone = "Phone number must be a numeric value";
    }
    else if(!Number.isInteger(Number(userData.phone))){
      newErrors.phone = "Phone number must be an integer and not contain decimal values";
    }
    else if (!userData.phone || userData.phone.length < 6 || userData.phone.length > 16) {
      newErrors.phone = "Phone number must be between 6 and 16 digits";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddUser = async () => {
    if (!validateForm()) return;

    const [firstName, ...lastNameParts] = userData.name.split(" ");
    const newUserData = {
      firstName,
      lastName: lastNameParts.join(" "),
      email: userData.email,
      phoneNumber: userData.phone,
      userRoleId: userData.userRoleId,
    };
    try {
      await addEMSPUser(newUserData);
      message.success("User Added Successfully.");
      getUsers();
      setAddUserModalOpen(false);
      resetUserData();
      setErrors({});
    } catch (error) {
      console.error("Error adding user:", error);
      const errorMessage = error?.response?.data?.message || "An Error Occurred While Adding The User.";
      message.error(errorMessage);
    }
  };

  const handleUpdateUser = async () => {
    if (!validateForm()) return;

    const [firstName, ...lastNameParts] = userData.name.split(" ");
    const updatedUserData = {
      firstName,
      lastName: lastNameParts.join(" "),
      email: userData.email,
      phoneNumber: userData.phone,
      userRoleId: userData.userRoleId,
    };
    try {
      await updateEMSPUser(selectedUser.id, updatedUserData);
      message.success("User Updated Successfully.");
      getUsers();
      setEditUserModalOpen(false);
      resetUserData();
      setErrors({});
    } catch (error) {
      console.error("Error updating user:", error);
      message.error(error?.response?.data?.message || "An Error Occurred While Adding The User.");
    }
  };

  const handleDeleteUser = async () => {
    setIsDeleteLoading(true);

    try {
      await deleteEMSPUser(selectedUserId);
      message.success("User Deleted Successfully.");

    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("Failed To Delete User.");
    }
    finally {
      getUsers();
      setOpenDeleteDialog(false);
      setSelectedUserId(null);
      setIsDeleteLoading(false);
    }
  };

  const handleOpenDeleteDialog = (userId, roleId) => {
    setOpenDeleteDialog(true);
    setSelectedUserId(userId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleAddOpenModal = () => {
    setAddUserModalOpen(true);
    setUserData({ name: "", email: "", phone: "", userRoleId: "" });
    setErrors({});
  };

  const resetUserData = () => {
    setUserData({ name: "", email: "", phone: "", userRoleId: "" });
    setSelectedUser(null);
    setErrors({});
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <MainLayout>
        <Typography component={"div"} overflow={0}>
      <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"} sx={{ marginBottom: "10px" }}>
        User Management
      </Typography>
      <Typography display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            id="s"
            variant="outlined"
            hiddenLabel
            placeholder="search"
            value={searchQuery}
            onChange={handleSearchInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
          <Typography component="span" sx={{ margin: "10px 0" }}>
            <Typography component="b">Portal Owner</Typography>: {userDetails.firstName + " " + userDetails.lastName}
          </Typography>
        </Typography>
        <Typography>
          <ButtonComponent
            label={
              <>
                <Add /> <Typography component="span">ADD User</Typography>
              </>
            }
            width="200px"
            height="40px"
            onClick={handleAddOpenModal}
          />
        </Typography>
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="55vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{maxHeight: "50vh"}}
          >
            <Table stickyHeader>
              <TableHead
              >
                <TableRow>
                  <TableCell align="center">User Name</TableCell>
                  <TableCell align="center">Email ID</TableCell>
                  <TableCell align="center">Mobile No.</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
              >
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.id}>
                      <TableCell align="center">
                        {user.firstName} {user.lastName} {user.isOwner ? "(Portal Owner)" : ""}
                      </TableCell>
                      <TableCell align="center">{user.email}</TableCell>
                      <TableCell align="center">{user.phoneNumber}</TableCell>
                      <TableCell align="center">{user.userRoleName}</TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => openEditUserModal(user)}>
                          <Edit />
                        </IconButton>
                        {user.userRoleName !== "Super Admin" && (
                          <IconButton
                            onClick={() => handleOpenDeleteDialog(user.id, user.userRoleName)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={users.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {/* Add User Modal */}
      <ModalComponent
        open={addUserModalOpen}
        headerText="Add User"
        handleClose={() => setAddUserModalOpen(false)}
        handleSubmit={handleAddUser}
        submitButtonLabel={"Add"}
      >
        <TextField
          label="Name"
          value={userData.name}
          onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
       {/* Use PhoneNumberInput component */}
          <PhoneComponent
            phoneNumber={userData.phone}
            setPhoneNumber={(number) => setUserData({ ...userData, phone: number })}
            errors={errors.phone}
          />
  
        <TextField
          select
          label="Role"
          value={userData.userRoleId}
          onChange={(e) => setUserData({ ...userData, userRoleId: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.userRoleId}
          helperText={errors.userRoleId}

        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </ModalComponent>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} disabled={isDeleteLoading ? true : false} color="primary">
            Cancel
          </Button>
          {isDeleteLoading ? <CircularProgress sx={{color:"#05336c", maxWidth: "40px"}} /> : <Button onClick={handleDeleteUser} color="secondary">
            Delete
          </Button>}
        </DialogActions>
      </Dialog>

      {/* Edit User Modal */}
      <ModalComponent
        open={editUserModalOpen}
        headerText="Edit User"
        handleClose={() => setEditUserModalOpen(false)}
        submitButtonLabel="Update"
        handleSubmit={handleUpdateUser}
      >
        <TextField
          label="Name"
          value={userData.name}
          onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Phone"
          value={userData.phone}
          onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.phone}
          helperText={errors.phone}
        />
        <TextField
          select
          label="Role"
          value={userData.userRoleId || selectedUser && selectedUser.userRoleId} // Default to selectedUser's role if userData's role isn't set
          onChange={(e) => setUserData({ ...userData, userRoleId: e.target.value })}
          fullWidth
          margin="normal"
          disabled={selectedUser && selectedUser.isOwner}
          error={!!errors.userRoleId}
          helperText={errors.userRoleId}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </ModalComponent>
    </ Typography>
    </MainLayout>
  );
}

export default UserManagementContainer;
