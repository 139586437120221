import React, { useState } from "react";
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    TextField,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
    IconButton,
    Chip,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { message } from "antd";

const ManageBankAccount = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [bankAccounts] = useState([
        {
            id: 1,
            bankName: "Bank of America",
            accountNumber: "**** **** **** 1234",
            status: "Approved",
            lastUpdated: "01/12/2024",
        },
        {
            id: 2,
            bankName: "Chase Bank",
            accountNumber: "**** **** **** 5678",
            status: "Pending",
            lastUpdated: "01/10/2024",
        },
        {
            id: 3,
            bankName: "Wells Fargo",
            accountNumber: "**** **** **** 9101",
            status: "Rejected",
            lastUpdated: "01/08/2024",
        },
    ]);

    const [kycForm, setKycForm] = useState({
        name: "",
        accountNumber: "",
        bankName: "",
        swiftCode: "",
        ifscCode: "",
        documents: [],
    });

    const [selectedDocumentType, setSelectedDocumentType] = useState("");

    const documentOptions = [
        { label: "Passport", value: "passport" },
        { label: "Driving License", value: "driving_license" },
        { label: "Utility Bill", value: "utility_bill" },
        { label: "National ID", value: "national_id" },
    ];

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setKycForm({ ...kycForm, [name]: value });
    };

    const handleDocumentTypeChange = (event) => {
        setSelectedDocumentType(event.target.value);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file.size > 200 * 1024) {
            message.error("File size cannot exceed 200KB.");
            return;
        }

        setKycForm((prevForm) => ({
            ...prevForm,
            documents: [
                ...prevForm.documents,
                { id: Date.now(), type: selectedDocumentType, file },
            ],
        }));
    };

    const handleDeleteDocument = (id) => {
        setKycForm((prevForm) => ({
            ...prevForm,
            documents: prevForm.documents.filter((doc) => doc.id !== id),
        }));
    };

    const handleFormSubmit = () => {
        alert("Form submitted. Implement API call here.");
        console.log("KYC Form:", kycForm);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "Approved":
                return "success.main";
            case "Pending":
                return "warning.main";
            case "Rejected":
                return "error.main";
            default:
                return "text.primary";
        }
    };

    return (
        <Box>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Bank Accounts" />
                <Tab label="Add Bank Details" />
            </Tabs>

            <Box sx={{ marginTop: 3 }}>
                {activeTab === 0 && (
                    <List>
                        {bankAccounts.map((account) => (
                            <ListItem
                                key={account.id}
                                sx={{
                                    border: "1px solid",
                                    borderColor: "divider",
                                    borderRadius: 2,
                                    padding: 2,
                                    marginBottom: 2,
                                }}
                            >
                                <ListItemText
                                    primary={`${account.bankName} (Account: ${account.accountNumber})`}
                                    secondary={`Last Updated: ${account.lastUpdated}`}
                                />
                                <Chip
                                    label={account.status}
                                    sx={{
                                        backgroundColor: getStatusColor(account.status),
                                        color: "white",
                                        marginLeft: 2,
                                    }}
                                />
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    sx={{ textTransform: "capitalize", marginLeft: 2 }}
                                >
                                    Edit
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                )}

                {activeTab === 1 && (
                    <Box
                        component="form"
                        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                        <TextField
                            label="Full Name"
                            name="name"
                            value={kycForm.name}
                            onChange={handleInputChange}
                            fullWidth
                        />

                        <TextField
                            label="Account Number"
                            name="accountNumber"
                            value={kycForm.accountNumber}
                            onChange={handleInputChange}
                            fullWidth
                        />

                        <TextField
                            label="Bank Name"
                            name="bankName"
                            value={kycForm.bankName}
                            onChange={handleInputChange}
                            fullWidth
                        />

                        <TextField
                            label="SWIFT Code"
                            name="swiftCode"
                            value={kycForm.swiftCode}
                            onChange={handleInputChange}
                            fullWidth
                        />

                        <TextField
                            label="IFSC Code"
                            name="ifscCode"
                            value={kycForm.ifscCode}
                            onChange={handleInputChange}
                            fullWidth
                        />

                        <FormControl fullWidth>
                            <InputLabel>Select Document Type</InputLabel>
                            <Select
                                value={selectedDocumentType}
                                onChange={handleDocumentTypeChange}
                                displayEmpty
                            >
                                {documentOptions.map((doc) => (
                                    <MenuItem key={doc.value} value={doc.value}>
                                        {doc.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                Select the document type to upload.
                            </FormHelperText>
                        </FormControl>

                        {selectedDocumentType && (
                            <Box>
                                <Typography sx={{ marginBottom: 1 }}>
                                    Upload{" "}
                                    {
                                        documentOptions.find(
                                            (doc) => doc.value === selectedDocumentType
                                        )?.label
                                    }:
                                </Typography>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<UploadIcon />}
                                    sx={{ width: "200px" }}
                                >
                                    Upload
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                </Button>
                            </Box>
                        )}

                        {kycForm.documents.length > 0 && (
                            <Box sx={{ marginTop: 2 }}>
                                <Typography variant="h6">Uploaded Documents:</Typography>
                                <List>
                                    {kycForm.documents.map((doc) => (
                                        <ListItem
                                            key={doc.id}
                                            sx={{
                                                border: "1px solid",
                                                borderColor: "divider",
                                                borderRadius: 2,
                                                padding: 1,
                                                marginBottom: 1,
                                            }}
                                        >
                                            <ListItemText
                                                primary={`${
                                                    documentOptions.find(
                                                        (option) => option.value === doc.type
                                                    )?.label
                                                }`}
                                                secondary={doc.file.name}
                                            />
                                            <IconButton
                                                edge="end"
                                                color="error"
                                                onClick={() => handleDeleteDocument(doc.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleFormSubmit}
                            sx={{ maxWidth: "200px", margin: "0 auto" }}
                        >
                            Submit Form
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default ManageBankAccount;
