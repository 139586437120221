import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, CircularProgress, IconButton, MenuItem, FormControl, Select, InputLabel, List, ListItem } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import Confetti from "react-confetti";
import { message } from "antd";
import CloseIcon from "@mui/icons-material/Close";
import ModalComponent from "../atoms/ModalComponent";
import { getSubscriptionPlan, initiateSubscriptionPurchaseRequest, updateSubscriptionPurchaseRequest } from "../../redux/apis/subscription";
import axios from "axios";
import { getCardDetails } from "../../redux/apis/cpo";
import {  useSelector } from "react-redux";
import { handleCamelCase } from "../../utils/helpers";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY); // Replace with your Stripe publishable key

const PlanShowModal = ({ showPlanModal, setShowPlanModal, fetchSubscriptionDetails, fetchInvoices }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(""); // No default card selection
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { innerWidth: width, innerHeight: height } = window;
  const [cards, setCards] = useState([]);
  

  const cpoEndPoint = useSelector((state) => state.cpo.endpoint) || "cpo";

 
  //fetch Cards
  const getCards = async () => {
    try {
        const response = await getCardDetails();
        setCards(response.list);
    }
    catch (error) {
        console.error("Error fetching cards:", error);
    }
}

useEffect(() => {
    getCards();
}, []);
  // Fetch available subscription plans
  const fetchSubscriptions = async () => {
    try {
      setLoading(true);
      const response = await getSubscriptionPlan();
      setPlans(response.list);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      message.error("Failed To Fetch Subscriptions");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const handlePaymentSubmit = async (plan) => {
    let purchaseReqId = "";
    setPaymentProcessing(true);

    try {
      const selectedCard = cards.find((card) => card.id === selectedCardId);
      if (!selectedCard) {
        message.error("Please Select A Card.");
        setPaymentProcessing(false);
        return;
      }

      const paymentMethod = selectedCard.stripeCardId; // Use the payment method ID of the selected saved card
      const customerId = selectedCard.stripeCustomerId;
      // Step 1: Initiate Subscription Purchase Request
      const purchaseResponse = await initiateSubscriptionPurchaseRequest(
        {
          "subscriptionPlanId":plan.id,
          "cardDetailsId":selectedCardId
      }
      );
      purchaseReqId = purchaseResponse.id;

      // Step 2: Create Payment Intent using Stripe
      const paymentIntentResponse = await axios.post("https://api.stripe.com/v1/payment_intents", null, {
        params: {
          amount: plan.name === "Free Plan" ? 0 : 1000, // Adjust amount based on the selected plan
          currency: "usd",
          payment_method: paymentMethod, // Use the payment method ID of the selected saved card
          confirmation_method: "manual",
          confirm: true,
          customer: customerId,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`, // Replace with your Stripe secret key
        },
      });

      const paymentIntent = paymentIntentResponse.data;

      if (paymentIntent.status === "succeeded") {

        // Step 3: Update subscription after payment
        await updateSubscriptionPurchaseRequest(purchaseReqId, {
          status: "Success",
          transactionPaymentId: paymentIntent.id,
        });

        // Payment successful
        setShowConfetti(true);
        setSelectedPlan(null);
        setShowConfetti(false);
        setShowPlanModal(false);
        message.success("Payment Successful!");

        setTimeout(() => {
          fetchInvoices();
          fetchSubscriptionDetails();
        }, 2000);
        
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "Payment Failed. Please Try Again."; // Default message if not available
        handleCamelCase(errorMessage)
        message.error(errorMessage); // Show the error message from the respons
    }

    setPaymentProcessing(false);
  };

  return (
    <ModalComponent
      open={showPlanModal}
      headerText={"Subscription Plans"}
      showSubmitButton={false}
      handleClose={() => setShowPlanModal(false)}
    >
      {loading ? (
        <Grid container sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}>
            {plans?.map((plan) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={plan.id}
                sx={{
                  background: "white",
                  borderRadius: "12px",
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                  padding: "2rem",
                  textAlign: "center",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 12px 28px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    marginBottom: "1rem",
                    color: plan.amount ? "#2196F3" : "#23CB10", // Adjust color based on plan type
                  }}
                >
                  {plan.name}
                </Typography>

                <Typography
                  sx={{
                    marginBottom: "1.5rem",
                    fontSize: "1.8rem",
                    fontWeight: "bold",
                    color: plan.amount ? "#2196F3" : "#23CB10", // Adjust color based on plan type
                  }}
                >
                  {plan.amount ? `$${plan.amount}` : "Free"}
                </Typography>

                <Typography sx={{ fontSize: '0.95rem', marginBottom: '1.5rem' }}>
                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>{plan?.allowedMaxCharger} Charger(s)</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>{plan?.allowedMaxUserAccounts} User(s)</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>{plan?.allowedMaxEvseStations} EVSE Station(s)</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                      <Typography>{plan?.allowedMaxRoles} Role(s)</Typography>
                    </ListItem>
                  </List>
                </Typography>

                {!(plan.name === "Free Plan") && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: plan.amount ? "#2196F3" : "#23CB10",
                      color: "white",
                      borderRadius: "12px",
                      padding: "10px 20px",
                    }}
                    onClick={() => setSelectedPlan(plan)}
                  >
                    {plan.amount ? "Get Started" : "Try Now"}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>

          {/* Payment Modal */}
          {selectedPlan && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  p: 4,
                  borderRadius: 2,
                  minWidth: "400px",
                  maxWidth: "90%",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{ position: "absolute", top: 8, right: 8 }}
                  onClick={() => setSelectedPlan(null)}
                >
                  <CloseIcon />
                </IconButton>

                <Typography variant="h6" mb={2}>
                  Confirm Payment for {selectedPlan.name}
                </Typography>

                {/* Dropdown to select a card */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="card-select-label">Select a Card</InputLabel>
                  <Select
                    labelId="card-select-label"
                    value={selectedCardId}
                    onChange={(e) => setSelectedCardId(e.target.value)}
                    label="Select a Card"
                  >
                    {cards?.map((card) => (
                      <MenuItem key={card.id} value={card.id}>
                        {`**** **** **** ${card.cardNumber.slice(-4)} - ${card.firstName } ${card.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 , color: "#fff"}}
                  disabled={paymentProcessing || !selectedCardId}
                  onClick={() => handlePaymentSubmit(selectedPlan)}
                >
                  {paymentProcessing ? (
                    <>
                      <CircularProgress size={24} sx={{ mr: 1 }} />
                      Processing payment...
                    </>
                  ) : (
                    "Pay Now"
                  )}
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}

      {showConfetti && (
        <Confetti width={width} height={height} recycle={false} sx={{ zIndex: 9999 }} numberOfPieces={500} />
      )}
    </ModalComponent>
  );
};

const PaymentModalWrapper = (props) => (
  <Elements stripe={stripePromise}>
    <PlanShowModal {...props} />
  </Elements>
);

export default PaymentModalWrapper;
