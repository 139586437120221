import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
} from '@mui/material';
import ModalComponent from '../../components/atoms/ModalComponent';
import { alpha } from '@mui/material/styles';
import MainLayout from '../../layout/mainLayout';
import { useTheme } from '@mui/styles';

const generateDummyData = () =>
  Array.from({ length: 4 }, (_, index) => {
    const transactionTypes = ['Refund', 'Purchase', 'Withdrawal', 'Transfer'];
    const transactionType = transactionTypes[index % transactionTypes.length];
    const statusOptions = {
      Refund: ['Approved', 'Denied'],
      Purchase: ['Approved', 'Denied'],
      Withdrawal: ['Completed', 'Failed'],
      Transfer: ['Completed', 'Scheduled'],
    };
    const status = statusOptions[transactionType][index % statusOptions[transactionType].length];

    const settlementStatuses = ['Completed', 'Scheduled', 'In Progress', 'Failed'];
    const settlementStatus = settlementStatuses[index % settlementStatuses.length];

    const grossAmount = (Math.random() * 100).toFixed(2);
    const fee = (Math.random() * 10).toFixed(2);
    const netAmount = (parseFloat(grossAmount) - parseFloat(fee)).toFixed(2);
    const currency = ['USD', 'CAD', 'EUR', 'GBP'][index % 4];

    

    return {
      serialNumber: index + 1,
      transactionId: `TXN${1000 + index}`,
      transactionType,
      grossAmount: `$${grossAmount}`,
      netAmount: `$${netAmount}`,
      fee: `$${fee}`,
      currency,
      availableOn: new Date(2024, 0, 18, 16, index).toLocaleString(),
      settlementStatus,
      settlementDate: new Date(2024, 0, 18, 16, index + 1).toLocaleString(),
      time: new Date(2024, 0, 18, 16, index + 2).toLocaleTimeString(),
      partner: `Partner ${String.fromCharCode(65 + index)}`,
      charger: `Charger #${100 + index}`,
      paymentMethod: index % 2 === 0 ? 'Credit Card' : 'Debit Card',
      cardType: index % 2 === 0 ? 'Visa' : 'MasterCard',
      refundedDate: transactionType === 'Refund' ? new Date(2024, 0, 19).toLocaleString() : null,
      transactionStatus: status,
    };
  });

const getStatusChipStyles = (status, theme) => {
  switch (status) {
    case 'Completed':
      return {
        backgroundColor: alpha(theme.palette.success.main, 0.1),
        color: theme.palette.success.dark,
      };
    case 'Failed':
      return {
        backgroundColor: alpha(theme.palette.error.main, 0.1),
        color: theme.palette.error.dark,
      };
    case 'Scheduled':
      return {
        backgroundColor: alpha(theme.palette.info.main, 0.1),
        color: theme.palette.info.dark,
      };
    default:
      return {
        backgroundColor: alpha(theme.palette.grey[500], 0.1),
        color: theme.palette.text.primary,
      };
  }
};

const generateRevenueSplit = (grossAmount) => [
  { name: 'TruCharge', percentage: 40 },
  { name: 'Mastercard', percentage: 30 },
  { name: 'Walmart', percentage: 30 },
].map((split) => ({
  ...split,
  amount: ((parseFloat(grossAmount.slice(1)) * split.percentage) / 100).toFixed(2),
}));

const StatusCard = ({ title, count, active , theme}) => (
  <Box
    sx={{
      padding: '10px 15px',
      textAlign: 'left',
      borderRadius: 3,
      border: active ? `2px solid ${theme.palette.tabActive}` : `2px solid ${theme.palette.tertiary.main}`,
      cursor: 'pointer',
      color: active ? theme.palette.tabActive : "#343434",
      flexGrow: 1,
    }}
  >
    <Typography sx={{ fontWeight: active ? '600' : 'normal' }}>{title}</Typography>
    <Typography variant="h6" sx={{ color: active ?  theme.palette.tabActive : '#343434', fontWeight: active ? '600' : 'normal' }}>
      {count}
    </Typography>
  </Box>
);

const FilterInput = ({ label, ...props }) => (
  <TextField
    label={label}
    variant="outlined"
    size="small"
    sx={{ width: '200px', marginRight: 2 }}
    {...props}
  />
);

const PaymentHistory = () => {
  const theme = useTheme();
  const [data, setData] = useState(generateDummyData());
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [revenueSplit, setRevenueSplit] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenModal = (transaction) => {
    setModalContent(transaction);
    if (transaction.settlementStatus === 'Completed') {
      setRevenueSplit(generateRevenueSplit(transaction.grossAmount));
    } else {
      setRevenueSplit([]);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MainLayout>
      <Box display="flex" gap={2} mb={3}>
        <StatusCard title="Authorized" count={data.filter(d => d.transactionStatus === 'Authorized').length} active  theme={theme}/>
        <StatusCard title="Failed" count={data.filter(d => d.transactionStatus === 'Failed').length} theme={theme} />
        <StatusCard title="Approved" count={data.filter(d => d.transactionStatus === 'Approved').length} theme={theme} />
        <StatusCard title="Denied" count={data.filter(d => d.transactionStatus === 'Denied').length} theme={theme} />
      </Box>

      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <FilterInput label="Date & Time" />
        <FilterInput label="Amount" />
        <FilterInput label="Currency" />
        <FilterInput label="Payment Method" />
        <Button variant="outlined" size="small" onClick={() => setData(generateDummyData())}>
          Clear Filters
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: "45vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Serial Number</TableCell>
              <TableCell align="center">Transaction ID</TableCell>
              <TableCell align="center">Transaction Type</TableCell>
              <TableCell align="center">Gross Amount</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Fee</TableCell>
              <TableCell align="center">Currency</TableCell>
              <TableCell align="center">Available On</TableCell>
              <TableCell align="center">Settlement Status</TableCell>
              <TableCell align="center">Settlement Date</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Charger</TableCell>
              <TableCell align="center">Payment Method</TableCell>
              <TableCell align="center">Card Type</TableCell>
              <TableCell align="center">Refunded Date</TableCell>
              <TableCell align="center">Transaction Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.transactionId} hover>
                <TableCell align="center">{row.serialNumber}</TableCell>
                <TableCell align="center">{row.transactionId}</TableCell>
                <TableCell align="center">{row.transactionType}</TableCell>
                <TableCell align="center">{row.grossAmount}</TableCell>
                <TableCell align="center">{row.netAmount}</TableCell>
                <TableCell align="center">{row.fee}</TableCell>
                <TableCell align="center">{row.currency}</TableCell>
                <TableCell align="center">{row.availableOn}</TableCell>
                <TableCell align="center">
                  <Chip
                    label={row.settlementStatus}
                    size="small"
                    sx={(theme) => getStatusChipStyles(row.settlementStatus, theme)}
 />
                </TableCell>
                <TableCell align="center">{row.settlementDate}</TableCell>
                <TableCell align="center">{row.time}</TableCell>
                <TableCell align="center">{row.charger}</TableCell>
                <TableCell align="center">{row.paymentMethod}</TableCell>
                <TableCell align="center">{row.cardType}</TableCell>
                <TableCell align="center">{row.refundedDate || 'N/A'}</TableCell>
                <TableCell align="center">{row.transactionStatus}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleOpenModal(row)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ModalComponent
        open={openModal}
        handleClose={handleCloseModal}
        headerText="Transaction Details"
        showSubmitButton={false}
      >
        <Box>
          <Typography variant="h4" component="h2" gutterBottom>
            Transaction Details
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Transaction ID</TableCell>
                  <TableCell>{modalContent.transactionId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Settlement Status</TableCell>
                  <TableCell>{modalContent.settlementStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                  <TableCell>{modalContent.settlementDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Time</TableCell>
                  <TableCell>{modalContent.time}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Gross Amount</TableCell>
                  <TableCell>{modalContent.grossAmount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Net Amount</TableCell>
                  <TableCell>{modalContent.netAmount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Fee</TableCell>
                  <TableCell>{modalContent.fee}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Currency</TableCell>
                  <TableCell>{modalContent.currency}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Partner</TableCell>
                  <TableCell>{modalContent.partner}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Charger</TableCell>
                  <TableCell>{modalContent.charger}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Payment Method</TableCell>
                  <TableCell>{modalContent.paymentMethod}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Card Type</TableCell>
                  <TableCell>{modalContent.cardType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Refunded Date</TableCell>
                  <TableCell>{modalContent.refundedDate || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Transaction Status</TableCell>
                  <TableCell>{modalContent.transactionStatus}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Revenue Breakdown Section */}
          {modalContent.settlementStatus === "Completed" && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Revenue Breakdown
              </Typography>
              <TableContainer component={Paper} sx={{ mb: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell sx={{ fontWeight: "bold" }}>Partner</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Percentage (%)</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Amount ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {revenueSplit.map((partner, idx) => (
                      <TableRow key={idx} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#fafafa" } }}>
                        <TableCell>{partner.name}</TableCell>
                        <TableCell>{partner.percentage}</TableCell>
                        <TableCell>${partner.amount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2} sx={{ fontWeight: "bold", textAlign: "right" }}>
                        Total Gross Amount
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        {modalContent.grossAmount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </ModalComponent>
    </MainLayout>
  );
};

export default PaymentHistory;