import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import ButtonComponent from "./button/button";
import { useTheme } from '@mui/material/styles';

const ModalComponent = ({
  open,
  handleClose,
  headerText,
  submitButtonLabel,
  children,
  showSubmitButton = true,
  handleSubmit,
}) => {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ddd", // Adjust border style as needed
    boxShadow: 2,
    display: "flex",
    flexDirection: "column",
    width: { xs: "80%", sm: "80%", md: "80%" },
    borderRadius: "29px 29px 0px 0px",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "29px",
    opacity: 1,
    maxHeight: "90%",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between", // Align items horizontally with spacing
    alignItems: "center", // Center header content vertically
    bgcolor: theme.palette.tertiary.main, // Adjust header background color as needed
    borderRadius: "29px 29px 0px 0px",
    padding: "12px 24px",
  };

  const contentStyle = {
    padding: "24px", // Adjust padding as needed
    // display: "grid", // Use grid layout for form fields

    gridTemplateColumns: {
      xs: "repeat(1, 1fr)",
      sm: "repeat(1, 1fr)",
      md: "repeat(2, 1fr)",
    }, // Two columns for form fields
    gap: "16px", // Spacing between form fields
  };


  const actionsStyle = {
    marginTop: "auto", // Push actions to bottom
    display: "flex",
    justifyContent: "center",
    padding: "20px", // Center buttons horizontally (or adjust as needed)
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={headerStyle}>
          <Typography
            variant="h6"
            component="h2"
            sx={{ color: "#333", fontWeight: "500" }}
          >
            {headerText}
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: "#999" }}>
            <CloseIcon /> {/* Add close icon */}
          </IconButton>
        </Box>
        <Box
          sx={contentStyle}
          overflow={"auto"}
          mb={"1rem"}
          className="layout_scroll"
        >
          {children}
        </Box>
        {showSubmitButton && (
          <Box sx={actionsStyle}>
            <ButtonComponent
              label={submitButtonLabel}
              width={"fit-content"}
              onClick={handleSubmit}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalComponent;
