import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Menu,
  Switch,
  FormControlLabel,
  Chip,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import {
  Close,
  MoreVert as MoreVertIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import MainLayout from "../layout/mainLayout";
import MongodbChartExample from "../components/Charts/mongodbChartExample";
import GoogleMapComponent from "../components/googleMap/googleMapComponent";
import StatusCard from "../components/dashboard/StatusCard";
import NotificationsComponent from "../components/dashboard/NotificationsComponent";
import { useTheme } from "@mui/material/styles";

function SortableMenuItem({ id, widget, index }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
    cursor: "grab",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Chip label={index + 1} size="small" style={{ marginRight: 8 }} />
      <DragIndicatorIcon style={{ marginRight: 8 }} />
      <span>{widget.name}</span>
    </div>
  );
}

function VisibilityMenuItem({ widget, onToggle }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #ddd",
      }}
    >
      <span>{widget.name}</span>
      <Switch checked={widget.visible} onChange={() => onToggle(widget.id)} />
    </div>
  );
}

function Dashboard() {
  const theme = useTheme();

  const initialWidgets = [
    { id: "statusCard", name: "Status Card", visible: true, component: <StatusCard /> },
    { id: "notifications", name: "Notifications", visible: true, component: <NotificationsComponent /> },
    { id: "mongodbChart", name: "Energy Consumed", visible: true, component: <MongodbChartExample /> },
    { id: "googleMap", name: "Google Map", visible: true, component: <GoogleMapComponent /> },
  ];

  const [widgets, setWidgets] = useState(initialWidgets);
  const [sortedWidgetIds, setSortedWidgetIds] = useState(initialWidgets.map((w) => w.id));
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState(0); // 0 = Sort, 1 = Visibility

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleToggleWidget = (id) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.id === id ? { ...widget, visible: !widget.visible } : widget
      )
    );
  };

  const handleRemoveWidget = (id) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) => (widget.id === id ? { ...widget, visible: false } : widget))
    );
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return;

    setSortedWidgetIds((prevIds) => {
      const oldIndex = prevIds.indexOf(active.id);
      const newIndex = prevIds.indexOf(over.id);
      return arrayMove(prevIds, oldIndex, newIndex);
    });
  };

  return (
    <MainLayout>
      {/* Menu Button */}
      <Grid container justifyContent="flex-end" marginBottom={2}>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          keepMounted
          PaperProps={{ style: { minWidth: "260px" } }} // Ensure proper width
        >
          {/* Tabs for Sorting & Visibility */}
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            TabIndicatorProps={{ style: { transition: "none" } }} // Prevent misalignment
          >
            <Tab label="Sort" style={{ minWidth: 120 }} />
            <Tab label="Visibility" style={{ minWidth: 120 }} />
          </Tabs>

          <Box
            style={{
              padding: "8px",
              width: "100%",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            {activeTab === 0 ? (
              /* Sorting Tab */
              <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={sortedWidgetIds} strategy={verticalListSortingStrategy}>
                  {sortedWidgetIds.map((widgetId, index) => {
                    const widget = widgets.find((w) => w.id === widgetId);
                    return (
                      <SortableMenuItem
                        key={widget.id}
                        id={widget.id}
                        widget={widget}
                        index={index}
                      />
                    );
                  })}
                </SortableContext>
              </DndContext>
            ) : (
              /* Visibility Tab */
              widgets.map((widget) => (
                <VisibilityMenuItem
                  key={widget.id}
                  widget={widget}
                  onToggle={handleToggleWidget}
                />
              ))
            )}
          </Box>
        </Menu>
      </Grid>

      {/* Responsive Grid */}
      <Grid container spacing={2}>
        {sortedWidgetIds.map((widgetId) => {
          const widget = widgets.find((w) => w.id === widgetId);
          return (
            widget.visible && (
              <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                key={widget.id}
                style={{
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    padding: "15px",
                    boxSizing: "border-box",
                    overflow: "visible",
                    maxHeight: "534px",
                  }}
                >
                  {/* Close Button */}
                  <IconButton
                    style={{ position: "absolute", top: 8, right: 8 }}
                    onClick={() => handleRemoveWidget(widget.id)}
                  >
                    <Close />
                  </IconButton>

                  {/* Render Widget */}
                  {widget.component}
                </div>
              </Grid>
            )
          );
        })}
      </Grid>
    </MainLayout>
  );
}

export default Dashboard;
