import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Chip,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import RetryIcon from '@mui/icons-material/Replay';
import { alpha } from "@mui/material/styles";
import ModalComponent from '../../../components/atoms/ModalComponent';
import { useTheme } from '@mui/styles';

const generateAssetData = () => {
  const statusOptions = ['Completed', 'Failed', 'Scheduled'];
  const bankNames = ['Bank of Montreal', 'Chase', 'HSBC', 'Citibank'];
  const settlementIds = Array.from({ length: 20 }, (_, i) => `ST-2024-${i + 1}`);

  return settlementIds.map((id) => {
    const transactions = generateTransactionData(id);
    const status = statusOptions[Math.floor(Math.random() * statusOptions.length)];
    const amount = transactions.reduce((sum, tx) => sum + tx.grossAmount, 0);
    const bank = bankNames[Math.floor(Math.random() * bankNames.length)];
    const settlementDate = new Date(2024, 0, Math.floor(Math.random() * 30) + 1).toLocaleDateString();
    const time = `${Math.floor(Math.random() * 12) + 1}:00 AM`;
    const revenueSplit = [
      { partnerName: 'Trucharge', percentageShare: 87, settlementAmount: 2000 },
      { partnerName: 'Mastercard', percentageShare: 12, settlementAmount: 300 },
      { partnerName: 'Walmart', percentageShare: 5, settlementAmount: 300 },
    ];

    return {
      settlementId: id,
      amount: `$${amount}`,
      bank,
      status,
      settlementDate,
      time,
      transactions,
      revenueSplit,
      settlementDetails: {
        fromDate: '01/01/2024',
        toDate: '01/31/2024',
        settlementPeriod: 'Monthly',
        totalTransactions: transactions.length,
        revenueGenerated: `${amount}`,
        amountToBeSettled: `${amount}`,
      },
    };
  });
};

const generateTransactionData = (settlementId) => {
  const transactionDates = Array.from({ length: 5 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - i);
    return date.toLocaleDateString();
  });

  return transactionDates.map((date, index) => {
    const grossAmount = Math.floor(Math.random() * 100) + 10;
    const fee = (Math.random() * 5).toFixed(2);
    const netAmount = (grossAmount - fee).toFixed(2);
    const percentageShare = "10"; // Example percentage share

    return {
      transactionId: `${settlementId}-TX-${index + 1}`,
      srNo: index + 1,
      transactionType: 'Purchase',
      grossAmount,
      fee,
      netAmount,
      percentageShare,
      calculatedAmount: (grossAmount * (parseFloat(percentageShare) / 100)).toFixed(2), // Calculate based on percentage share
      status: 'Completed',
    };
  });
};

// Store the generated data once outside of the component
const initialData = generateAssetData();

const SettlementsTab = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSettlement, setSelectedSettlement] = useState(null);
  const [transactionPage, setTransactionPage] = useState(0);
  const [transactionRowsPerPage, setTransactionRowsPerPage] = useState(5);
  const theme = useTheme();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (settlement) => {
    setSelectedSettlement(settlement);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTransactionChangePage = (event, newPage) => {
    setTransactionPage(newPage);
  };

  const handleTransactionChangeRowsPerPage = (event) => {
    setTransactionRowsPerPage(parseInt(event.target.value, 10));
    setTransactionPage(0);
  };

  const getStatusChipStyles = (status, theme) => {
    switch (status) {
      case "Completed":
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.dark,
        };
      case "Failed":
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
          color: theme.palette.error.dark,
        };
      case "Scheduled":
        return {
          backgroundColor: alpha(theme.palette.info.main, 0.1),
          color: theme.palette.info.dark,
        };
      default:
        return {
          backgroundColor: alpha(theme.palette.grey[500], 0.1),
          color: theme.palette.text.primary,
        };
    }
  };

  const getActiveStep = (status) => {
    switch (status) {
      case 'Completed':
        return 2;
      case 'Scheduled':
        return 1;
      case 'Failed':
        return 0;
      default:
        return 0;
    }
  };
  // Custom Step Icon Component for Styling
  // Custom Step Icon Component for Styling
  const CustomStepIcon = (props) => {
    const { active, completed, icon } = props;

    const iconStyle = {
      backgroundColor: completed || active ? theme.palette.tertiary.main : theme.palette.tabActive,
      color: completed || active ?   theme.palette.tabActive : "#FFFFFF",
      borderRadius: `50%`,
      border: `1px solid ${theme.palette.tabActive}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
    };

    return (
      <div style={iconStyle}>
        {icon}
      </div>
    );
  };
   
  const convertShare = (share, percentage) => {
    console.log(share, percentage);
    return (share.slice(1) * (Number(percentage) / 100)).toFixed(2);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: '10px' }}>
        View and verify your settlements status
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: '40vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">Serial Number</TableCell>
              <TableCell align="center">Settlement ID</TableCell>
              <TableCell align="center">Settlement Amount</TableCell>
              <TableCell align="center">Receivable Amount</TableCell>
              <TableCell align="center">Bank/Card</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Settlement Date/Arrived on</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {initialData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={row.settlementId}>
                <TableCell align="center">{index + page * rowsPerPage + 1}</TableCell>
                <TableCell align="center">{row.settlementId}</TableCell>
                <TableCell align="center">{row.amount}</TableCell>
                <TableCell align="center">{"$" + convertShare(row.amount, row.transactions[0].percentageShare)}</TableCell>
                <TableCell align="center">{row.bank}</TableCell>
                <TableCell align="center">
                  <Chip label={row.status} size="small" sx={(theme) => getStatusChipStyles(row.status, theme)} />
                </TableCell>
                <TableCell align="center">{row.settlementDate}</TableCell>
                <TableCell align="center">{row.time}</TableCell>
                <TableCell align="center">
                  {row.status === 'Failed' ? (
                    <Button
                      variant="outlined"
                      startIcon={<RetryIcon />}
                      onClick={() => console.log(`Retrying settlement ${row.settlementId}`)}
                    >
                      Retry
                    </Button>
                  ) : (
                    <Button variant="outlined" onClick={() => handleViewDetails(row)}>
                      View Details
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={initialData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ModalComponent open={openModal} handleClose={handleCloseModal} title={`Settlement Details for ${selectedSettlement?.settlementId}`} showSubmitButton={false}>
        {selectedSettlement && (
          <>
            <Box sx={{ display: 'flex', gap: 5, alignItems: 'center', marginBottom: '15px' }}>
              <div>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                  Settlement ID: {selectedSettlement.settlementId}
                </Typography>
                <Typography variant="h6" sx={{ marginBottom: '10px', color: '#555' }}>
                  Settlement Amount: {selectedSettlement.amount}
                </Typography>
              </div>
              {selectedSettlement.status === 'Completed' && (
                <Typography
                  sx={{
                    padding: '5px 10px',
                    border: '1px solid #d3d3d3',
                    borderRadius: '4px',
                    backgroundColor: '#e8f 5e9',
                    color: 'green',
                    fontWeight: 'bold',
                  }}
                >
                  Paid
                </Typography>
              )}
            </Box>

            <Stepper
              activeStep={getActiveStep(selectedSettlement.status)}
              orientation="vertical"
              sx={{ marginBottom: '20px' }}
            >
              <Step>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  Payout Initiated (Date: {selectedSettlement.settlementDate}, Time: {selectedSettlement.time})
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={CustomStepIcon}>Processing</StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={CustomStepIcon}>Completed</StepLabel>
              </Step>
            </Stepper>

            <Box sx={{ marginBottom: '20px', padding: '5px', boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)', borderRadius: '4px' }}>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', padding: "5px" }}>
                Settlement Details
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>From Date</TableCell>
                    <TableCell>{selectedSettlement.settlementDetails.fromDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>To Date</TableCell>
                    <TableCell>{selectedSettlement.settlementDetails.toDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Settlement Period</TableCell>
                    <TableCell>{selectedSettlement.settlementDetails.settlementPeriod}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Transactions</TableCell>
                    <TableCell>{selectedSettlement.settlementDetails.totalTransactions}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Revenue Generated</TableCell>
                    <TableCell>${selectedSettlement.settlementDetails.revenueGenerated}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Amount to be Settled</TableCell>
                    <TableCell>${selectedSettlement.settlementDetails.amountToBeSettled}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box sx={{ marginBottom: '20px', padding: '10px', boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)', borderRadius: '4px' }}>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                Summary
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction Count</TableCell>
                    <TableCell>Total Gross Amount</TableCell>
                    <TableCell>Total Fees</TableCell>
                    <TableCell>Net Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{selectedSettlement.transactions.length}</TableCell>
                    <TableCell>${selectedSettlement.transactions.reduce((sum, tx) => sum + tx.grossAmount, 0)}</TableCell>
                    <TableCell>${selectedSettlement.transactions.reduce((sum, tx) => sum + parseFloat(tx.fee), 0).toFixed(2)}</TableCell>
                    <TableCell>${selectedSettlement.transactions.reduce((sum, tx) => sum + parseFloat(tx.netAmount), 0)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Typography variant="subtitle1" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
              Transactions
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: '20px', borderRadius: '4px', backgroundColor: '#f9f9f9', boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">Transaction ID</TableCell>
                    <TableCell align="center">Transaction Type</TableCell>
                    <TableCell align="center">Gross Amount</TableCell>
                    <TableCell align="center">Fee</TableCell>
                    <TableCell align="center">Net Amount</TableCell>
                    <TableCell align="center">Percentage Share</TableCell>
                    <TableCell align="center">Calculated Amount</TableCell> {/* New column */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSettlement.transactions.slice(transactionPage * transactionRowsPerPage, transactionPage * transactionRowsPerPage + transactionRowsPerPage).map((transaction, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{transaction.srNo}</TableCell>
                      <TableCell align="center">{transaction.transactionId}</TableCell>
                      <TableCell align="center">{transaction.transactionType}</TableCell>
                      <TableCell align="center">${transaction.grossAmount}</TableCell>
                      <TableCell align="center">${transaction.fee}</TableCell>
                      <TableCell align="center">${transaction.netAmount}</TableCell>
                      <TableCell align="center">{transaction.percentageShare + "%"}</TableCell>
                      <TableCell align="center">${transaction.calculatedAmount}</TableCell> {/* Display calculated amount */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={selectedSettlement.transactions.length}
              rowsPerPage={transactionRowsPerPage}
              page={transactionPage}
              onPageChange={handleTransactionChangePage}
              onRowsPerPageChange={handleTransactionChangeRowsPerPage}
            />
          </>
        )}
      </ModalComponent>
    </Box>
  );
};

export default SettlementsTab;